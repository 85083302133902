import { Indicador } from './indicador';

export class Alerta  extends Indicador {
  total = 0;

  constructor(data?: any | Alerta) {
    super();
    if (data !== undefined) {
      Object.assign(this, data);
    }
  }
}
