export class Point {
  lat: number;
  lng: number;

  constructor(lat: number, lng: number){
    this.lat = lat;
    this.lng = lng;
  }

//  TODO implementar margen dispersion del GPS para una misma posicion

  equals( point: Point): boolean  {
    return this.lat === point.lat && this.lng === point.lng;
  }

}
