

export class Grant {
    client = '';
    id = 0;
    blockedByCompany: string[] = [];
    grantedAccess: string[] = [];
    restrictedAccess: string[] = [];
    specialsUsersBlocked = [];
    specialsUsersGranted = [];
  }
