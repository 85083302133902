<div class="simbologia">
  <span class="simbo-text">
    <i class="material-icons color-encendido">
      brightness_1
    </i>
    : Encendido
  </span>
  <span class="simbo-text">
    <i class="material-icons color-apagado">
      brightness_1
    </i>
    : Apagado
  </span>
  <span class="simbo-text">
    <i class="material-icons color-detenido">
      brightness_1
    </i>
    : Detenido
  </span>
  <span class="simbo-text">
    <i class="material-icons color-off">
      brightness_1
    </i>
    : Offline
  </span>
  <span class="simbo-text">
    <i class="material-icons color-online">
      brightness_1
    </i>
    : Online
  </span>
  <span class="simbo-text">
    <i class="material-icons color-taller">
      brightness_1
    </i>
    : En taller
  </span>
</div>
