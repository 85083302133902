import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { WPSID } from 'app/_class/wpsid';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  wpsid: WPSID = new WPSID('');

  getGroups(idCliente: number) {
    const token = (this.cookieService.check('wpsid')) ? `&${this.wpsid.getUrlEncode()}` : '' ;
    const url = `${environment.endPoint}/WebappAdmin/GetGrupoArea?opt=grcl&cliente=${idCliente}${token}`;
    return this.http.get(url);
  }


  getZones(idCliente: number) {
    const token = (this.cookieService.check('wpsid')) ? `&${this.wpsid.getUrlEncode()}` : '' ;
    const url = `${environment.endPoint}/WebappAdmin/GetArea?opt=clte&cliente=${idCliente}&publi=false${token}`;
    return this.http.get(url);
  }

  async getZonesList(idCliente: number ) {
    const token = (this.cookieService.check('wpsid')) ? `&${this.wpsid.getUrlEncode()}` : '' ;
    const url = `${environment.endPoint}/WebappAdmin/GetArea?opt=clte&cliente=${idCliente}&publi=false${token}`;
    let list: any;

    await this.http.get(url).toPromise().then(res => {
      console.log("el listado de zonas" , res);
      list = res;
    }).catch(error => console.log("Error al cargar zonas:",Error(error)));

    return list;
  }
}
