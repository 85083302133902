import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simbologia-mapa',
  templateUrl: './simbologia-mapa.component.html',
  styleUrls: ['./simbologia-mapa.component.css']
})
export class SimbologiaMapaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
