<div class="mapa-dialog">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

  <app-mapa
    [setVehicles]="vehicles"
    [setFocus]="focus"
    [setRoutePoints]="points"
    [setvehicleDetail]="true"
  ></app-mapa>

  <!-- Tabla -->
  <div class="mat-table-light">
    <div class="mat-row-light">
      <div class="mat-cell-light"><b>Patente:</b> <span *ngIf="!isLoading">{{item.W3}}</span>
        <span *ngIf="isLoading">{{noDisponible}}</span>
      </div>
      <div class="mat-cell-light"><b>Descripción:</b> <span *ngIf="!isLoading">{{item.D1}}</span>
        <span *ngIf="isLoading">{{noDisponible}}</span>
      </div>
      <div class="mat-cell-light"><b>Velocidad:</b> <span *ngIf="!isLoading">{{item.W12}}</span>
        <span *ngIf="isLoading">{{noDisponible}}</span>
      </div>
    </div>
    <div class="mat-row-light">
      <div class="mat-cell-light"><b>Satelites:</b> <span *ngIf="!isLoading">{{item.W13}}</span>
        <span *ngIf="isLoading">{{noDisponible}}</span>
      </div>
      <div class="mat-cell-light"><b>Orientación:</b> <span *ngIf="!isLoading">{{item.W15}}</span>
        <span *ngIf="isLoading">{{noDisponible}}</span>
      </div>
      <div class="mat-cell-light"><b>Alimentación:</b> <span *ngIf="!isLoading">{{alimentacion}}</span>
        <span *ngIf="isLoading">{{noDisponible}}</span>
      </div>
    </div>
    <div class="mat-row-light">
      <div class="mat-cell-light"><b>Ignición:</b> <span>{{ignicion}}</span></div>
      <div class="mat-cell-light"><b>Fecha GPS:</b> <span> {{fechaGPS | date: 'HH:mm:ss dd/MM/yyyy'}}</span></div>
      <div class="mat-cell-light"><b>Hora Registro:</b> <span> {{fechaRegistro | date: 'HH:mm:ss dd/MM/yyyy'}}</span>
      </div>
    </div>
    <div class="mat-row-light" *ngIf=" !isLoading &&  item.W42?.length > 0">
      <div class="mat-cell-light"><b>Temperatura:</b>
        <span> {{(item.W17 > -40) ? item.W17 + 'ºC' : noDisponible}}</span></div>
      <div class="mat-cell-light"><b>Temperatura:</b>
        <span> {{(item.W18 > -40) ? item.W18 + 'ºC' : noDisponible}}</span></div>
      <div class="mat-cell-light"><b>Temperatura:</b>
        <span> {{(item.W38 > -40) ? item.W38 + 'ºC' : noDisponible}}</span></div>
    </div>
    <div class="mat-row-light">
      <div class="mat-cell-light"><b>Horometro:</b> <span *ngIf="!isLoading">{{horometro}}</span>
        <span *ngIf="isLoading">{{noDisponible}}</span></div>
      <div class="mat-cell-light"><b>Odómetro:</b> <span *ngIf="!isLoading">{{odometro}}</span>
        <span *ngIf="isLoading">{{noDisponible}}</span></div>
      <div class="mat-cell-light center-content">
        <em class="material-icons">
          person
        </em><b>Conductor:</b>
        <span *ngIf="!isLoading" class="break">
                {{conductor}}
              </span>
        <span *ngIf="isLoading">{{noDisponible}}</span>
      </div>
    </div>
    <div class="mat-row-light">
      <div class="mat-cell-light center-content">
        <em class="material-icons">
          place
        </em><b>Dirección:</b>
        <span *ngIf="isDireccion" class="break">
              {{item !== undefined ? item.direccion : ''}}
              </span>
        <span *ngIf="!isDireccion">{{noDisponible}}</span>
      </div>
    </div>
    <div class="mat-row-light" *ngIf="ioData?.length > 0">
      <div class="mat-cell-light" *ngFor="let ioInfo of ioData">
        <div *ngIf="item.W19 >= 0 && ioInfo.ioNumero==1" >
          <b >
            IO1-{{ioInfo.ioNombre}} {{ioInfo.ioSubclase}}:</b>
            <span *ngIf="item.W19 >= 0 && ioInfo.ioNumero==1">
              {{item.W19==1 ? ioInfo.estadoActivo : ioInfo.estadoInactivo }}
            </span>  
          <span *ngIf="isLoading">{{noDisponible}}</span>
        </div>
        <div *ngIf="item.W20 >= 0 && ioInfo.ioNumero==2" >
        
          <b >
            IO2-{{ioInfo.ioNombre}} {{ioInfo.ioSubclase}}:</b>
            <span *ngIf="item.W20 >= 0 && ioInfo.ioNumero==2">
              {{item.W20==1 ? ioInfo.estadoActivo : ioInfo.estadoInactivo }}
            </span>
           
          <span *ngIf="isLoading">{{noDisponible}}</span>
        </div>
        <div *ngIf="item.W21 >= 0 && ioInfo.ioNumero==3" >
          
          <b >
            IO3-{{ioInfo.ioNombre}} {{ioInfo.ioSubclase}}:</b>
            <span *ngIf="item.W21 >= 0 && ioInfo.ioNumero==3">
              {{item.W21==1 ? ioInfo.estadoActivo : ioInfo.estadoInactivo }}
            </span>   
          <span *ngIf="isLoading">{{noDisponible}}</span>
        </div>
        <div *ngIf="item.W22 >= 0 && ioInfo.ioNumero==4" >
          <b >
            IO4-{{ioInfo.ioNombre}} {{ioInfo.ioSubclase}}:</b>
            <span *ngIf="item.W22 >= 0 && ioInfo.ioNumero==4">
              {{item.W22==1 ? ioInfo.estadoActivo : ioInfo.estadoInactivo }}
            </span>       
          <span *ngIf="isLoading">{{noDisponible}}</span>
        </div>
      </div>
      
     
    </div>
    <div class="mat-row-light" *ngIf="bateriaGPS != null" >
      <div class="mat-cell-light"><b>Bateria GPS:</b> <span *ngIf="!isLoading">{{bateriaGPS}}</span>
        <span *ngIf="isLoading">{{noDisponible}}</span>
      </div>
    </div>
  </div>
</div>
<div align="center">
  <button mat-flat-button (click)="close()">Cerrar</button>
  <button mat-flat-button class="btn-historial" (click)="verHistorial()">Ver Historial</button>
</div>
