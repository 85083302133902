<mat-accordion>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header class="opciones-btn">
            <mat-panel-title>
                Filtros historial de ruta
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="filtro-desplegado">
            <div class="opciones-filtros">
                <div class="opciones-list">
                    <app-select-con-filtro
                        [setOpciones]="this.options.clients"
                        [deshabilitado]="false" 
                        [label]="'Cliente'" 
                        [setOpcion]=""
                        [multiple] = false
                        (opcionSeleccionada)="setSelect($event, 'client')">
                    </app-select-con-filtro>
                </div>
                <div class="opciones-list">
                    <app-select-con-filtro
                        [setOpciones]="this.options.groups"
                        [deshabilitado]="false"
                        [label]="'Grupo'" 
                        [setOpcion]=""
                        [multiple] = false
                        (opcionSeleccionada)="setSelect($event, 'group')">
                    </app-select-con-filtro>
                </div>
                <div class="opciones-list">
                    <app-select-con-filtro
                        [setOpciones]="this.options.fleets"
                        [deshabilitado]="false" 
                        [label]="'Flota'" 
                        [setOpcion]=""
                        [multiple] = false
                        (opcionSeleccionada)="setSelect($event, 'fleet')">
                    </app-select-con-filtro>
                </div>
                <div class="opciones-list">
                    <app-select-con-filtro
                        [setOpciones]="this.options.vehicles"
                        [deshabilitado]="false" 
                        [label]="'Vehiculo'" 
                        [setOpcion]=""
                        [multiple] = false
                        (opcionSeleccionada)="setSelect($event, 'vehicle')">
                    </app-select-con-filtro>
                </div>
                <div class="opciones-list">
                    <app-select-con-filtro
                        [setOpciones]="this.options.stopTimes"
                        [deshabilitado]="false" 
                        [label]="'Detencion'" 
                        [setOpcion]=""
                        [multiple] = false
                        (opcionSeleccionada)="setSelect($event, 'stopTime')">
                    </app-select-con-filtro>
                </div>
                <div class="opciones-list">
                    <app-select-con-filtro
                        [setOpciones]="this.options.speeds"
                        [deshabilitado]="false" 
                        [label]="'Velocidad'" 
                        [setOpcion]=""
                        [multiple] = false
                        (opcionSeleccionada)="setSelect($event, 'speed')">
                    </app-select-con-filtro>
                </div>
                <p class="texto-opciones">Periodo de tiempo:</p>
                <div class="opciones-list-2">
                    <div class="text-halft-col">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Fecha inicial</mat-label>
                            <input matInput [matDatepicker]="datePickerFrom"  [(ngModel)]="this.options.startDateTime" style="color:black" (click)="datePickerFrom.open()">
                            <mat-datepicker-toggle matSuffix (click)="datePickerFrom.open()"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #datePickerFrom></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="text-halft-col-2">
                        <mat-select class="time" [(ngModel)]="this.options.hourStart">
                            <mat-option *ngFor="let item of this.options.hours" value='{{item.id}}' >
                                {{ item.descripcion }}
                            </mat-option>
                        </mat-select>
                        :
                        <mat-select class="time" [(ngModel)]="this.options.minuteStart">
                            <mat-option *ngFor="let item of this.options.minutes" value='{{item.id}}' >
                                {{ item.descripcion }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="opciones-list-2">
                    <div class="text-halft-col">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Fecha final</mat-label>
                            <input matInput [matDatepicker]="datePickerTo"  [(ngModel)]="this.options.endDateTime" style="color:black !important" (click)="datePickerTo.open()">
                            <mat-datepicker-toggle matSuffix (click)="datePickerTo.open()"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #datePickerTo></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="text-halft-col-2">
                        <mat-select class="time" [(ngModel)]="this.options.hourEnd">
                            <mat-option *ngFor="let item of this.options.hours" value='{{item.id}}' >
                                {{ item.descripcion }}
                            </mat-option>
                        </mat-select>
                        :
                        <mat-select class="time" [(ngModel)]="this.options.minuteEnd">
                            <mat-option *ngFor="let item of this.options.minutes" value='{{item.id}}' >
                                {{ item.descripcion }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="opciones-list-3">
                    <mat-slide-toggle color="primary" [(ngModel)]="this.options.ignition" >
                        Historial solo con ignici&oacute;n
                    </mat-slide-toggle>
                </div>
                <p class="texto-opciones">Precisión Minima:</p>
                <div class="opciones-list-3">
                    <mat-radio-group color="primary" aria-label="Precisi&oacute;n" (onChange)="setSelect($event, 'precision')">
                        <mat-radio-button value="3,4.0">Baja</mat-radio-button>
                        <mat-radio-button value="5,2.4" checked>Media</mat-radio-button>
                        <mat-radio-button value="7,1.0" >Alta</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="text-center">
                <button mat-button mat-flat-button color="primary" class="btn btn-block" (click)="doFilter()">Consultar</button>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
