import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiUsers } from 'app/_interfaces/ClientSite/api-users';
import { JsonHc } from 'app/_interfaces/json-hc';

/*
 Servicio para consumir  data de http endpoint
*/
@Injectable({
  providedIn: 'root'
})
export class HTTPService {

  constructor(private http: HttpClient) { }

  getVehiculosByUser(userId: number, param: string): Observable<ApiUsers> {
    const url = `${environment.endPoint}/ClientSite/api/users/${userId}/vehicles/?${param}`;
    return this.http.get<ApiUsers>(url);
  }

  getVehiculosFlota(param: string): Observable<JsonHc> {
    const url = `${environment.endPoint}/Req/GetJsonHC?f=attribute&flota=true&${param}`;
    return this.http.get<JsonHc>(url);
  }

  getRegistroHcByImei(imei: number, urlParam: string): Observable<JsonHc> {
    const url = `${environment.endPoint}/Req/GetJsonHC?f=attribute&imei=${imei}&${urlParam}`;
    return this.http.get<JsonHc>(url);
  }

}
