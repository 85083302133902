import { Injectable } from '@angular/core';
import { WPSID } from 'app/_class/wpsid';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  wpsid: WPSID = new WPSID('');

  constructor(private http: HttpClient) { }

  getClients() {
    const url = environment.endPoint + '/Req/GetCliente?' + this.wpsid.getUrlEncode();
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8')
    };
    return this.http.get(url, httpOptions);
  }
}
