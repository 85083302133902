import { Injectable, EventEmitter } from '@angular/core';
import { Alert } from "../_interfaces/alerts";
import { Howl } from 'howler';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public notificationEvent: EventEmitter<Alert[]> = new EventEmitter();

  constructor() { }

  open(alerts: Alert[]) {
    this.notificationEvent.emit(alerts);
    if (alerts.find(e => e.alertType === 102 || e.alertType === 2)) {
      this.playSoundPanic();
    } else {
      this.playSound();
    }
  }

  playSound() {
    const sound = new Howl({
      src: ['./notification.wav']
    });
    sound.play();
  }
  playSoundPanic() {
    const sound = new Howl({
      src: ['./panico.wav']
    });
    sound.play();
  }
}
