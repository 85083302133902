import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { WPSID } from 'app/_class/wpsid';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { JsonHc } from "../../_interfaces/json-hc";
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RealtimeDataService {

  wpsid: WPSID = new WPSID('');

  constructor(private http: HttpClient) {
  }

  getVehiclesList(idCliente: number) {
    const url = `${ environment.endPoint }/Req/GetJsonHC?idcliente=${ idCliente }&${ this.wpsid.getUrlEncode() }&st=true`;
    return this.http.get(url);
  }

  getVehicleByImei(imei: number) {
    const url = `${ environment.endPoint }/Req/GetJsonHC?imei=${ imei }&${ this.wpsid.getUrlEncode() }&st=true`;
    return this.http.get(url);
  }



  getVehicleByImeiLatest(imei: number, ioInfo: boolean): Observable<any> {
    const getObservable = this.getVehicleByImei(imei);
    
    return forkJoin({     // Combinamos el GET y POST para usar info de ambos
      getResponse: getObservable,
      postResponse: getObservable.pipe(
        switchMap((res: JsonHc) => {
          const vehicleIds: number[] = res.contenido.registro.map(registro => registro.W1);
          const url2 = `${environment.endPointApiInstantanea}?ioInfo=${ioInfo}`;
          return this.http.post(url2, { vehicleIds });
        })
      )
    });
  }

  getVehicleByIdVehicle(idCliente: number, ioInfo: boolean) {
    const url1 = `${ environment.endPoint }/Req/GetJsonHC?idcliente=${ idCliente }&${ this.wpsid.getUrlEncode() }&st=true`;
    return this.http.get<JsonHc>(url1).pipe(
      switchMap((res: JsonHc) => {
        const vehicleIds: number[] = res.contenido.registro.map(registro => registro.W1);
        const url2 = `${ environment.endPointApiInstantanea }?ioInfo=${ioInfo}`;
        return this.http.post(url2, { vehicleIds});
      })
    );
  }

}
