import { Permiso } from 'app/_interfaces/permiso';

export class PermisoClass implements Permiso {
  id: string;
  acces: string;
  name: string;
  path: string;
  childrens: Permiso[];

  constructor() {
    this.id = '';
    this.acces = '';
    this.name = '';
    this.path = '';
    this.childrens = [];

  }
}
