import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { WPSID } from 'app/_class/wpsid';
import { Grupo } from 'app/_class/grupo';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  wpsid: WPSID = new WPSID('');

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  async getGrupos(idCliente: number) {

    // Token es la cookie at (access token) de cognito. Necesario si se quiere consultar
    // los grupos de un cliente filtrados por los que tiene disponible el usuario.
    // El filtro solo aplica a usuarios supervisores y normales, mas no a administradores.
    let token = '';
    if ( this.cookieService.check('at') ) {
      token = '&user_token=' + this.cookieService.get('at');
    }
    const url = `${environment.endPoint}/Req/GetGrupo?id_cliente=${idCliente}${token}`;

    let groups: Grupo[] = [];

    await this.http.get<Grupo[]>(url)
    .toPromise()
    .then(data => {
      groups = data;
    });

    return groups;

  }
}
