export class Options {
    clientSelected = 0;
    groupSelected = 0;
    fleetSelected = 0;
    vehicleSelected = 0;
    stopTimeSelected = 0;
    speedSelected = 0;
    hdop = 2.4;

    clients = [];
    groups = [];
    fleets = [];
    vehicles = [];
    stopTimes = [
        { id: 1, descripcion: 'Mas de 1 min.' },
        { id: 2, descripcion: 'Mas de 2 min.' },
        { id: 3, descripcion: 'Mas de 3 min.' },
        { id: 4, descripcion: 'Mas de 4 min.' },
        { id: 5, descripcion: 'Mas de 5 min.' },
        { id: 10, descripcion: 'Mas de 10 min.' },
        { id: 15, descripcion: 'Mas de 15 min.' },
        { id: 30, descripcion: 'Mas de 30 min.' },
        { id: 60, descripcion: 'Mas de 1 hora' },
        { id: 120, descripcion: 'Mas de 2 horas' },
    ];
    startDateTime = new Date();
    endDateTime = new Date();
    timezone = new Date().getTimezoneOffset();

    ignition = true;
    precision = 3;

    speeds = [{ id: 0, descripcion: 'Automático' }];
    hours = [{ id: 0, descripcion: '00' }];
    minutes = [{ id: 0, descripcion: '00' }];

    hourStart = 0;
    minuteStart = 0;
    hourEnd = 23;
    minuteEnd = 0;

    constructor() {
        let i = 10;
        while (i < 155) {
            this.speeds.push({ id: i, descripcion: `${i} kms/h` });
            i += 5;
        }

        i = 1;
        while (i < 24) {
            const opt = i < 10 ? '0' + i : i;
            this.hours.push({ id: i, descripcion: opt + '' });
            i++;
        }

        i = 1;
        while (i < 60) {
            const opt = i < 10 ? '0' + i : i;
            this.minutes.push({ id: i, descripcion: opt + '' });
            i++;
        }

        console.log("minutes", this.minutes);
        console.log("hours", this.hours);
    }
}
