import { RealtimeDataService } from 'app/_services/realtimeData/realtime-data.service';
import { MapOptions } from 'app/_class/map-options';
import { Vehiculo } from 'app/_class/vehiculo';
import { ClientsService } from 'app/_services/clients/clients.service';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { interval } from 'rxjs';
import { AlertsByClient } from "../../_interfaces/alerts";
import { NotificationService } from "../../_services/notification.service";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: 'app-flota-completa',
  templateUrl: './flota-completa.component.html',
  styleUrls: ['./flota-completa.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlotaCompletaComponent implements OnInit, OnDestroy {
  @Input()
  public clients = [];

  public reloadPoints = [];
  public vehicleId;
  public filteredVehicles: Vehiculo[] = [];

  public mapOptions: MapOptions = new MapOptions();
  autoUpdate = true;
  flotaCompleta = true;
  vehicleFocused = 0;
  private alive = true;
  private timeToRefreshData = 30000;
  public points = [];
  public zones = [];
  public selectedClients = [];
  public alertByClient: AlertsByClient[] = [];

  constructor(
    private clientsService: ClientsService,
    private realtimeService: RealtimeDataService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.clientsService.getClients()
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: any[]) => {
        this.clients = data.map(c => {
          return {
            id: c.id,
            descripcion: c.name
          };
        });
      });
  }

  setSelectedClients(clients: any[]) {
    this.selectedClients = clients;
  }

  setZones(zones: any) {
    this.zones = zones;
  }

  setPoints(points: any) {
    this.points = points;
  }

  ngOnDestroy() {
    this.resetAllData();
    this.alive = false;
  }
  resetAllData() {
    // Aquí va tu lógica para resetear los datos
    this.clients = [];
    this.reloadPoints = [];
    this.filteredVehicles = [];
    this.mapOptions = new MapOptions();
    this.vehicleFocused = 0;
    this.timeToRefreshData = 30000;
    this.points = [];
    this.zones = [];
    this.selectedClients = [];
    this.alertByClient = [];
    this.autoUpdate = false;
    this.flotaCompleta = false;
    this.doReloadPoints([]);
    console.log('Data has been reset');
  }

  setVehiclesFiltered(vehicles: Vehiculo[]) {
    vehicles.sort((a,b) => a.patente.localeCompare(b.patente));
    this.filteredVehicles = vehicles;
  }

  async setMapOptions(mapOptions: MapOptions) {
    this.mapOptions = mapOptions;
  }



  setFocus(id) {
    this.vehicleFocused = id;
  }

  loadInterval() {
    interval(this.timeToRefreshData)
      .takeWhile(() => (this.alive && this.filteredVehicles.length > 0))
      .subscribe(() => {
        this.reloadVehicleData();
      });
  }
  setAlerts(alerts: AlertsByClient[]) {
    this.alertByClient = alerts;
  }

  reloadVehicleData() {
    console.log('reloading data');
  }

  doReloadPoints(ev: any) {
    this.reloadPoints = [];
    this.reloadPoints = ev;
  }

  setNotification(alert: any) {
    this.vehicleId = alert.idVehicle;
  }
  openNotification(ev: any) {
    this.notificationService.open(ev);
  }
}
