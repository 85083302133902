<div class="mapa-dialog">
  <div *ngFor="let alert of this.alertVehicle">
    <div class="mat-table-light">
      <!-- Fila 1 -->
      <div class="mat-row-light">
        <div class="mat-cell-light">
          <b>Patente:</b>
          <span *ngIf="!isLoading">{{ alert.plate }}</span>
          <span *ngIf="isLoading">{{ noDisponible }}</span>
        </div>
        <div class="mat-cell-light">
          <b >Fecha:</b>
          <span *ngIf="!isLoading">{{ alert.initEvent | date:'dd-MM-yyyy HH:mm:ss' }}</span>
          <span *ngIf="isLoading">{{ noDisponible }}</span>
        </div>
        <div class="mat-cell-light">
          <b>Tipo:</b>
          <span *ngIf="!isLoading">{{ alert.nameEvent }}</span>
          <span *ngIf="isLoading">{{ noDisponible }}</span>
        </div>
      </div>
    </div>
  </div>
  <div align="center">
    <button mat-flat-button (click)="close()">Cerrar</button>
  </div>
</div>
