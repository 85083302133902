import { Component, OnInit, Input, EventEmitter, Output, Injectable } from '@angular/core';

@Component({
  selector: 'app-mapa-historico',
  templateUrl: './mapa-historico.component.html',
  styleUrls: ['./mapa-historico.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class MapaHistoricoComponent implements OnInit {
  visible = false;
  @Input() set setPuntoOrigen(origen: any) {
    this.puntoOrigen = origen;
  }

  @Input() set setPuntoDestino(destino: any) {
    this.puntoDestino = destino;
  }

  @Input() set setMarcadores(marcadores: any) {
    this.marcadores = marcadores;
    this.dibujarRutaDirections();
  }

  @Input() set setPuntosRuta(puntos: any) {
    this.puntosRuta = puntos;
  }

  @Input() set setPolyline(polyline: any[]) {
    this.polyline = polyline;
  }

  @Input() set setTrazarRuta(trazar: boolean) {
    this.trazarRuta = trazar;
    this.dibujarRutaDirections();
  }

  @Input() set dibujar(dibujar: boolean) {
    this.dibujarRutaDirections();
  }

  @Input() public inicio = null;

  @Output() whenSetParada = new EventEmitter<any>();

  public marcadores = [];
  public trazarRuta = false;
  public polyline = [];
  public puntosRuta = [];

  public lat: number;
  public lng: number;
  private map: any;
  public fitbounds = false;
  public origenTrazo = {};
  public destinoTrazo = {};
  public puntoOrigen = null;
  public puntoDestino = null;
  public paradasTrazo: any;

  zoom = 12;
  private callbackReady: any;

  public mcar = 'http://maps.google.com/mapfiles/kml/paddle/blu-circle.png';

  public agmMarkers: AgmMarker[] = [];
  constructor() { }

  dibujarRutaDirections() {
    let i = 0;
    let origen = {};
    let destino = {};
    const waypoints = [];
    this.origenTrazo = {};
    this.destinoTrazo = {};
    this.paradasTrazo = [];
    for (const marcador of this.marcadores) {
      if (marcador) {
        if (i === 0) {
          origen = { lat: marcador.lat, lng: marcador.lng };
        } else
          if (i === this.marcadores.length - 1) {
            destino = { lat: marcador.lat, lng: marcador.lng };
          } else {
            waypoints.push({ location: { lat: marcador.lat, lng: marcador.lng }, stopover: true });
          }
        i++;
      }
    }

    this.origenTrazo = origen;
    this.destinoTrazo = destino;
    this.paradasTrazo = waypoints;

  }

  onChange(event) {
    this.whenSetParada.emit({ geoevent: event });
  }

  public existeReferencia() {
    return this.map !== undefined;
  }

  mapReady(map: any) {
    this.map = map;
    this.map.setCenter({
      lat: -33.4221357,
      lng: -70.6038334
    });
    if (this.callbackReady) {
      this.callbackReady();
    }
  }

  eliminarMarcadores() {
    this.agmMarkers = [];
  }

  agregarMarcadorSinIndice(latitud: number, longitud: number) {
    try {

      this.callbackReady = function() {
        this.agmMarkers.push({
          lat: latitud,
          lng: longitud,
          icn: '',
          id: latitud + longitud,
          label: ' '
        });
        this.map.panTo({
          lat: this.lat,
          lng: this.lng
        });

        this.zoom = 13;
        this.map.zoom = 13;
        this.map.minZoom = 2;
        this.map.maxZoom = 16;
      };
    } catch (e) {
      console.log(e);
    }
  }
  agregarMarcador(latitud: number, longitud: number, indice: number) {
    this.origenTrazo = {};
    this.destinoTrazo = {};
    this.paradasTrazo = [];

    this.fitbounds = true;
    let agregado = false;
    for (const m of this.agmMarkers) {
      if (m.id === indice) {
        m.lat = latitud;
        m.lng = longitud;
        agregado = true;
        break;
      }
    }
    if (!agregado) {
      this.agmMarkers.push({
        lat: latitud,
        lng: longitud,
        icn: `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${indice}|00a7e1|000000`,
        id: indice,
        label: ' '
      });
    }
    let i = 0;
    let origen = {};
    let destino = {};
    const waypoints = [];

    this.agmMarkers.sort((a, b) => {
      return a.id - b.id;
    });

    for (const marcador of this.agmMarkers) {
      if (i === 0) {
        origen = { lat: marcador.lat, lng: marcador.lng };
      } else
        if (i === this.agmMarkers.length - 1) {
          destino = { lat: marcador.lat, lng: marcador.lng };
        } else {
          waypoints.push({ location: { lat: marcador.lat, lng: marcador.lng }, stopover: true });
        }
      i++;
    }

    this.origenTrazo = origen;
    this.destinoTrazo = destino;
    this.paradasTrazo = waypoints.slice();

  }

  mover() {
    this.map.panTo({
      lat: this.lat,
      lng: this.lng
    });
    this.map.setZoom(this.zoom);
  }

  getDirectionsRuta() {

  }

  ngOnInit() {
  }

}

interface AgmMarker {
  lat?: number;
  lng?: number;
  icn?: string;
  id?: number;
  label: string;
}
