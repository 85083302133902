import { LatLngLiteral, MapsAPILoader, MarkerManager } from '@agm/core';
import { ControlPosition, MapTypeControlOptions } from '@agm/core/';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Subscription } from 'rxjs';

import { Vehiculo } from './../../../_class/vehiculo';
import { Zona } from './../../../_class/zona';
import { MapOptions } from 'app/_class/map-options';
import { DetalleMapaComponent, InputDetalleMapa } from 'app/_components/detalle-mapa/detalle-mapa.component';
import { DataService } from 'app/_services/data.service';
import { LoginService } from 'app/_services/login.service';
import { PointsService } from 'app/_services/points/points.service';
import { DetalleAlertaComponent } from "../../detalle-alerta/detalle-alerta.component";
import { Alert, AlertsByClient } from "../../../_interfaces/alerts";
import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: [ './mapa.component.css' ],
})
export class MapaComponent implements OnInit, OnDestroy {

  actualZoom = 12;
  zonesList: Zona[] = [];
  alerts: AlertsByClient[] = [];
  vehiclesInAlert: Alert[] = [];
  pointsList: any[] = [];
  private blinkingIntervals: {[id: string]: any} = {};
  address: string;
  private geoCoder;
  routePoints = [];
  idClientConfigurate: number[] = [];

  @Input() autoUpdate = false;

  @Input() flotaCompleta = false;

  @Input() clients = [];

  @Input() set setAlerts(alerts: AlertsByClient[]) {
    this.alerts = alerts || [];

    this.vehiclesInAlert = [];
    const seenVehicles = new Set<number>();
    for ( const alertByClient of this.alerts ) {
      for ( const alert of alertByClient.alerts ) {
        if ( !seenVehicles.has(alert.idVehicle) ) {
          seenVehicles.add(alert.idVehicle);
          this.vehiclesInAlert.push(alert);
        }
      }
    }
  }

  @Input() set setRoutePoints(points: []) {
    this.routePoints = points;

  }

  @Input() set setFocus(focus) {
    const {lat, lng, id} = focus;
    this.centerMap(Number(lat), Number(lng), id);
    const v = this.vehicles.filter((vh: Vehiculo) => vh.id === id);
    if ( v[0] !== undefined ) {
      this.showInfoWindow(v[0]);
    }
  }

  @Input() set setPanToVehicle(id: number) {
    const marker = this.markers[id];

    if ( marker && marker !== undefined ) {
      const mlat = marker.position.lat();
      const mlng = marker.position.lng();
      this.centerMap(mlat, mlng, id);
    }
  }

  @Input() set setNotification(id: number) {
    if ( id !== null && id !== undefined && this.vehicles.find(e => e.id === id)) {
      const vehicle: Vehiculo = this.vehicles.find(e => e.id === id);
      this.centerMap(Number(vehicle.point.lat), Number(vehicle.point.lng), id);
      this.showInfoWindow(vehicle);
    }
  }

  @Input() set zones(zs: Zona[]) {
    if ( zs.length > 0 ) {
      this.setZones(zs);
    } else {
      this.zonesList = [];
    }
  }

  @Input() set serchMarker(pts: any) {
  }

  @Input() set points(pts: any) {
    this.pointsList = pts.filter(p => p !== undefined && p !== null).map((p: any) => {
      return {
        lat: Number(p.LAT[0]),
        lng: Number(p.LON[0]),
        name: p.NOMBRE[0],
        desc: p.DESCRIPCION[0],
        icon: {
          url: `./${ this.getIconFromPoint(p).replace('png', 'svg') }`,
          scaledSize: new google.maps.Size(15, 15),
          labelOrigin: new google.maps.Point(15, 15)
        }
      };
    });
  }

  @Input() set setMapOptions(mapOptions: MapOptions) {

    const prev = this.mapOptions;

    if ( this.mapOptions !== mapOptions ) {
      this.mapOptions = mapOptions;
      this.clearMap();
      this.changeVehicles(this.vehicles)
      this.updateMap(this.vehicles, mapOptions.showLabels)

      if ( prev.showTraffic !== mapOptions.showTraffic ) {
        this.toggleTraffic();
      }

      if ( prev.automaticFit !== mapOptions.automaticFit && this.mapOptions.automaticFit ) {
        this.fitBounds();
      }

      if ( prev.showPoints !== mapOptions.showPoints ) {
        this.activatePoints();
      }
    }
  }

  isDetailVehicle = false;

  @Input() set setvehicleDetail(isDetail: boolean) {
    this.isDetailVehicle = isDetail;
  }

  addressPoint = null;

  /** Controla la posicion del marcador al ingresar una direccion */
  @Input() set positionSetted(pseted) {
    if ( pseted === null || google === undefined ) {
      return;
    }

    const {lat, lng} = pseted;

    this.addressPoint = new google.maps.Marker({
      position: {lat, lng},
      map: this.agmap
    });

    this.centerMap(lat, lng, 15);

  }

  changeVehicles(vehiclesObject: any) {
    this.vehicles = Object.values(vehiclesObject).map(it => it);
    this.vehiclesSize = this.vehicles.length;
  }

  clearMap() {
    this.deleteCluster()
    this.removeMarkers()
  }

  updateMap(vehiclesObject: object = null, showLabels: boolean = false) {
    //al actualizar, borra vehiculos que el cliente ya no tiene asignado (markers y clusters)
    if ( vehiclesObject ) {
      this.deleteMarkersFromRemovedVehicles(vehiclesObject);
    }
    // agrega y actualiza makers
    this.loadMarkers(showLabels).then((nuevosMarkers) => {
      // crea/actualiza clusters
      if ( !this.clusterMap ) {
        this.createClusters(nuevosMarkers)
      } else {
        this.addMarkersToCluster(nuevosMarkers)
        this.repaintCluster()
      }
    }).catch((error) => {
      console.log("error load markers:", Error(error));
    });
  }


  @Input() set setVehicles(vehiclesObject: Object) {
    if ( Object.keys(vehiclesObject).length !== 0 ) {
      this.changeVehicles(vehiclesObject)
      if ( this.changedClient ) {
        this.changedClient = false;
        this.clearMap();
        this.updateMap();
        this.fitBounds();
      } else {
        this.updateMap(vehiclesObject)
      }
      if ( this.mapOptions.automaticFit ) {
        this.fitBounds();
      }
    } else {
      this.clearMap();
    }
  }

  @Output() selectedVehicle = new EventEmitter<number>();
  @Output() toggleInfoWindow = new EventEmitter<Vehiculo>();
  @Output() emitNewPoint = new EventEmitter<any>();
  @Output() emitNewNotification = new EventEmitter<any>();

  mapOptions = new MapOptions();
  vehicles = [];
  markers = {};
  alertMarkers = {};
  inputDetalleMapa: InputDetalleMapa;
  agmap: any = null;
  mapTypeControlOptions: MapTypeControlOptions = {
    position: ControlPosition.TOP_LEFT
  };
  vehiclesSize = 0;
  clusterMap: any = null;
  infoWindows = {};
  trafficLayer = null;
  isTraffic = false;
  infoWindow = null;
  lat = -33.4476981;
  lng = -70.6405132;
  bounds = null;
  idCliente: any = null; // id cliente seleccionado
  changedClient = false;
  checkPoints: any;
  markersPoints = [];
  clientes = [];
  nameZona = '';
  infoWindowPoint = null;
  latPoint = null;
  lngPoint = null;
  markerPoint = null;
  visibleZone = false;
  nameInput = '';
  descriptionInput = '';
  option = '';
  options: any = [];
  searchAddresText = '';
  country

  renderer = {
    render({count, position}, stats) {
      const imageSizes = [ 53, 56, 66, 78, 90 ];
      const clusterSizes = [ 10, 100, 1000, 5000 ];
      // obtiene el index del icono en base a la cantidad de objetos en el cluster
      let index = 0;
      let dv = Math.floor(count / 10);
      while ( dv > 0 ) {
        dv = Math.floor(dv / 10);
        index++;
      }
      index = Math.min(index, imageSizes.length);
      const url = `./cluster-${clusterSizes[index]}.svg`;
      const size = imageSizes[index]
      return new google.maps.Marker({
        position,
        icon: {
          url: url,
          scaledSize: new google.maps.Size(size, size),
        },
        label: {
          text: String(count),
          color: "#FFFFFF",
          fontSize: "11px",
          fontWeight: "bold"
        },
        // adjust zIndex to be above other markers
        zIndex: 1000 + count,
      });
    }
  }

  serchedMarker = null;
  autocomplete = null;
  serchbox = null;
  private x: Subscription;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public markerManager: MarkerManager,
    private http: HttpClient,
    public dialog: MatDialog,
    private router: Router,
    private pointService: PointsService,
    private loginService: LoginService,
    private dataService: DataService) {
  }

  ngOnDestroy(): void {
    this.x.unsubscribe();
  }

  @ViewChild('search', {static: false})
  public searchElementRef: ElementRef;

  @ViewChild('serchbutton', {static: false})
  public searchButtonRef: ElementRef;


  countryOptions = {
    componentRestrictions: {country: [ 'cl', 'mx' ]}
  };

  ngOnInit() {
    this.x = this.dataService.$idCliente.subscribe((x) => {
      this.clientes = this.loginService.clientes;
      this.idCliente = this.loginService.idCliente;
      this.changedClient = true;
    });
    this.getClientConfigurateWithTxt().then(r => true);

    this.mapsAPILoader.load().then(() => {
      //Inicializa el geolocalizador
      this.geoCoder = new google.maps.Geocoder;
      this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, this.countryOptions);
      this.iplocation()
      //Inicializa el cuadro con la dirección buscada
      const infowindow = new google.maps.InfoWindow();
      this.autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
          //verify result
          if ( place.geometry === undefined || place.geometry === null ) {
            return;
          }
          this.getAddress(place.geometry.location.lat(), place.geometry.location.lng(), infowindow)
        });
      });
    }).catch(error => {
      console.log("Error al cargar:", Error(error));
    });

  }

  onKeydown(event) {
    if ( event.key === "Enter" ) {
      this.searchButtonRef.nativeElement.focus();
      this.searchButtonRef.nativeElement.click();
    }
  }

  searchByCoords() {
    this.geocodeLatLng();
  }

  private geocodeLatLng() {
    const infowindow = new google.maps.InfoWindow();
    const input = this.searchAddresText;
    this.actualZoom = 12;
    const latlngStr = input.split(",", 2);

    if ( parseFloat(latlngStr[0]) && parseFloat(latlngStr[1]) ) {
      this.getAddress(parseFloat(latlngStr[0]), parseFloat(latlngStr[1]), infowindow);
    } else {
      this.searchElementRef.nativeElement.focus()
    }
  }


  iplocation() {
    navigator.geolocation.getCurrentPosition((position: Position) => {
      this.geoCoder.geocode({
        'location': {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      }, (results, status) => {
        if ( status === 'OK' ) {
          for ( var i = 0; i < results.length; i++ ) {
            if ( results[i].types[0] === 'country' ) {
              this.country = results[i].address_components[0].short_name
              this.countryOptions = {componentRestrictions: {country: [ this.country ]}}
              this.autocomplete.componentRestrictions.country = this.country
            }
          }
        }
      });
    })
  }

  getAddress(latitude, longitude, infowindow) {
    this.geoCoder.geocode({'location': {lat: latitude, lng: longitude}}, (results, status) => {
      const latlng = {
        lat: latitude,
        lng: longitude
      };
      if ( status === 'OK' ) {
        if ( results[0] ) {

          this.address = results[0].formatted_address;

          //crea el icono en el mapa con la dirección
          const googleMarker = new google.maps.Marker({
            position: latlng,
            map: this.agmap,
          });

          //crea la ventana con la dirección
          infowindow.setContent(results[0].formatted_address);
          infowindow.open(this.agmap, googleMarker);

          //Metodo que centra mapa en la dirección buscada
          this.centerMap(latitude, longitude, 22);
        } else {
          window.alert('No se encontraron resultados');
        }
      } else {
        window.alert('Hubeo un fallo con Geocoder: ' + status);
      }

    });
  }

  openPopUp(v: Vehiculo) {  //no es un vehiculo, es solo su IMEI
    const config = new MatDialogConfig();
    config.autoFocus = true;
    this.inputDetalleMapa = new InputDetalleMapa();

    //  busca el registroHc del cliente seleccionado ó de la flota
    this.inputDetalleMapa.data = null;
    this.inputDetalleMapa.vehicle = v;
    this.inputDetalleMapa.isDireccion = this.mapOptions.showDirections;
    this.inputDetalleMapa.needIO = this.isClientConfigurate();
    this.inputDetalleMapa.clientesConfigurados = this.idClientConfigurate;
    this.inputDetalleMapa.idCliente = this.idCliente;

    config.data = this.inputDetalleMapa;
    this.dialog.open(DetalleMapaComponent, config);

    this.dialog.afterAllClosed.subscribe(_ => {
      this.inputDetalleMapa = null;
    });
  }

  getAlertsByVehicle(v: Vehiculo) {
    const matchingAlerts = this.alerts.reduce((acc, alertByClient) => {
      return acc.concat(alertByClient.alerts.filter(alert => alert.idVehicle === v.id));
    }, []);
    return matchingAlerts.length > 0 ? matchingAlerts : [];
  }

  openPopUpAlerta(v: Vehiculo) {
    const config = new MatDialogConfig();
    config.autoFocus = true;
    config.data = {
      alert: this.getAlertsByVehicle(v)
    };
    const dialogRef = this.dialog.open(DetalleAlertaComponent, config);

    dialogRef.afterClosed().subscribe(result => {

      // Haz algo más si es necesario
    });
  }

  loadMarkers(showLabels = false) {
    return new Promise((solve, reject) => {
      try {
        const nuevosMarkers = []
        let i = 1;
        this.vehicles.forEach(v => {
          if ( v === undefined ) {
            return;
          }
          i++;
          if ( this.markers[v.id] === undefined ) {
            this.markers[v.id] = this.createVehicleMarker(v, showLabels);
            this.createInfoWindow(v);
            nuevosMarkers.push(this.markers[v.id])
          } else {
            this.moveMarker(v);
            this.toggleLabel(v);
          }
        });
        solve(nuevosMarkers);
      } catch ( error ) {
        console.log("Error:", error);
        reject(error);
      }
    });
  }

  toggleLabel(v: Vehiculo) {
    if ( this.mapOptions.showLabels ) {
      this.markers[v.id].setLabel(this.getLabelsProps(v));
    } else {
      this.markers[v.id].setLabel('');
    }
  }

  setZones(zs) {
    try {
      if ( google && google !== undefined && google.maps.geometry !== undefined ) {
        this.zonesList = zs.filter(z => z.encodedPoints && z.encodedPoints !== undefined).map((z: Zona) => {
          return {
            ...z,
            path: google.maps.geometry.encoding.decodePath(z.encodedPoints)
          };
        });
      } else {
        setTimeout(() => {
          this.setZones(zs);
        }, 1500);
      }
    } catch ( e ) {
      console.log('Error setZones', e);
    }
  }

  getIconFromPoint(point) {
    return point.ICONO[0];
  }

  showZoneName(z: Zona) {
    this.nameZona = z.nombre;
  }

  hideZoneName() {
    this.nameZona = '';
  }

  createClusters(markers: Object) {
    if ( this.agmap && this.mapOptions.showCluster && !this.clusterMap ) {
      this.clusterMap = new MarkerClusterer({
        map: this.agmap,
        markers: Object.values(markers),
        renderer: this.renderer
      });
    }
  }

  deleteCluster() {
    if ( this.clusterMap ) {
      this.clusterMap.clearMarkers();
      this.clusterMap.render();
      this.clusterMap = null;
    }
  }

  repaintCluster() {
    if ( this.clusterMap ) {
      this.clusterMap.render();
    }
  }

  addMarkersToCluster(m) {
    if ( this.clusterMap ) {
      this.clusterMap.addMarkers(m);
    }
  }

  deleteMarkerFromCluster(m) {
    if ( this.clusterMap ) {
      this.clusterMap.removeMarker(m);
    }
  }

  reloadMarkers(showLabels = false) {
    console.log("Reloading markers...");
    this.removeMarkers();
    setTimeout(() => {
      this.loadMarkers(showLabels)
        .catch(err => console.log("Error al reload:", Error(err)));
    }, 100);

  }

  deleteMarkersFromRemovedVehicles(vehiclesObject: Object) {
    const idsVehicles = Object.values(vehiclesObject).filter(v => v && v.id).map(v => v.id.toString())
    // elimina markers y clusters
    Object.keys(this.markers).forEach(mk => {
      if ( !idsVehicles.includes(mk) ) {
        this.deleteMarkerFromCluster(this.markers[mk])
        this.markers[mk].setMap(null);
        delete this.markers[mk];
      }
    });
  }

  fitBounds() {
    if ( this.vehicles.length < 2 ) {
      return;
    }

    try {
      this.bounds = new google.maps.LatLngBounds();
      this.vehicles.forEach(v => {
        this.bounds.extend({lat: Number(v.point.lat), lng: Number(v.point.lng)});
      });
      if ( this.agmap ) {
        this.agmap.fitBounds(this.bounds);
      }
    } catch ( error ) {
      // do nothing
    }
  }

  removeMarkers() {
    Object.keys(this.markers).forEach(mk => {
      this.markers[mk].setMap(null);
      delete this.markers[mk];
    });
  }

  async moveMarker(v: Vehiculo) {
    const mlat = this.markers[v.id].position.lat() + '';
    const mlng = this.markers[v.id].position.lng() + '';
    const alat = v.point.lat + '';
    const alng = v.point.lng + '';

    // Mover siempre el marcador, independientemente del estado de alerta
    if (alat !== mlat || alng !== mlng) {
      const position = this.googleMapLatLng(v.point.lat, v.point.lng);
      this.markers[v.id].setPosition(position);
    }

    // Verifica si el estado de alerta ha cambiado
    const isInAlert = this.vehiclesInAlert.some(e => e.idVehicle === v.id);

    // Si hay una alerta y el vehículo no tiene un alertMarker asociado, crea uno.
    if (isInAlert && !this.alertMarkers[v.id]) {
      console.log('Creando alerta para vehículo:', v.id);

      const alertIconPath = this.iconAlert(this.vehiclesInAlert.filter(e => e.idVehicle === v.id));
      const alertIconOptions = {
        url: alertIconPath,
        scaledSize: new google.maps.Size(50, 50),
        labelOrigin: new google.maps.Point(8, 50),
      };

      this.markers[v.id].setIcon(alertIconOptions);
      this.alertMarkers[v.id] = true;

      const vehicle: any = this.getAlertsByVehicle(v);
      this.emitNewNotification.emit(vehicle);
    } else if (!isInAlert) {
      const currentIcon = this.markers[v.id].getIcon().url;
      const defaultIcon = this.getIcon(v).url;

      if (currentIcon !== defaultIcon) {
        this.markers[v.id].setIcon(this.getIcon(v));
      }

      if (this.alertMarkers[v.id]) {
        console.log('Eliminando alerta para vehículo:', v.id);
        this.alertMarkers[v.id] = false;
      }
    }
  }


  iconAlert(alertVehicle: Alert[]) {
    if ( alertVehicle.find(e => e.alertType === 102 || e.alertType === 2) ) {
      return './iconoRojo.svg';
    } else if ( alertVehicle.find(e => e.idOpenDoor === 'Apertura de Puerta Trasera') ) {
      return './iconoAmarillo.svg';
    }
    return './iconoGris.svg';
  }

  async createInfoWindow(v: Vehiculo) {

    this.markers[v.id].addListener('click', async () => {
      this.showInfoWindow(v);
    });
  }

  async closeInfowindows() {
    if ( this.infoWindow ) {
      this.infoWindow.close();
      this.infoWindow = null;
    }
  }

  async showInfoWindow(v: Vehiculo) {
    this.closeInfowindows()
    if ( !this.isDetailVehicle ) {
      const content = this.createInfoWindowContentUnifi(v);
      this.infoWindow = new google.maps.InfoWindow({
        content
      });
    }

    await this.infoWindow.open(this.agmap, this.markers[v.id]);
    this.addListenerButtons(v);
    setTimeout(() => {
      this.iWtemperatureButtons(v);
    }, 50);

  }

  createInfoWindowContent(v: Vehiculo) {
    const element = document.createElement('div');

    element.innerHTML =
      `<span class="titulo-tooltip">${ v.patente } ${ v.descripcion !== '' ? '-' + v.descripcion : '' }</span>
      <hr>
      <div class="flex-item-2">
        <div id="wDetalle">
        </div>
        <div id="wHistorial">
        </div>
      </div>
      <div id="wTemp">
      </div>`;

    return element;

  }


  showButtons(v: Vehiculo) {
    const detalle = 'btnDetalle';
    const historial = 'btnHistorial';


    const elementDetalle = document.getElementById('wDetalle');
    const elementHistorial = document.getElementById('wHistorial');
    const buttonDetalle = `<button
                            id="btnDetalle"
                            class="mat-button btn-primary">
                              Detalle
                          </button>`;
    const buttonHistorial = `<button
                              id="btnHistorial"
                              class="mat-button">
                                Historial
                            </button>`;
    try {
      elementDetalle.innerHTML = buttonDetalle;
      elementHistorial.innerHTML = buttonHistorial;

      document.getElementById(detalle).addEventListener('click', () => {
        this.centerMap(Number(v.point.lat), Number(v.point.lng), v.imei);
        this.openPopUp(v.imei);
      });

      document.getElementById(historial).addEventListener('click', () => {
        this.verHistorial.bind(this);
        this.verHistorial(v.imei);
      });
    } catch ( error ) {
      setTimeout(() => {
        this.showButtons(v);
      }, 500);
    }
  }

  iWtemperatureButtons(v: Vehiculo) {
    const temp = 'temp';
    const element = document.getElementById('wTemp');

    if ( !element ) {
      return;
    }

    if ( !v.temperatura.isTemperatura ) {
      element.innerHTML = '';
    } else {
      element.innerHTML = `<div><hr><span class="titulo-tooltip">Sensores de temperatura</span><br>`;

      for ( let i = 1; i < 5; i++ ) {
        const adc = `adc${ i }`;
        if ( v.temperatura.validacion(v.temperatura[adc]) ) {
          element.innerHTML += `<button id="${ temp }-${ i }" class="mat-button">${ i } : ${ v.temperatura.adc1 }°</button>`;

          document.getElementById(`${ temp }-${ i }`).addEventListener('click', () => {
            this.verTemperatura.bind(this);
            this.verTemperatura(v.imei, i);
          });
        }
      }
    }
  }

  googleMapLatLng(lat: number, lng: number): google.maps.LatLng {
    return new google.maps.LatLng(lat, lng);
  }

  getSVG(color: string, pointCog: any): string {
    return `<svg
    width="99.58223mm"
    height="94.044456mm"
    viewBox="0 0 99.582229 94.044456"
    version="1.1"
    id="svg5"
    sodipodi:docname="flecha-verde.svg"
    inkscape:export-filename="../b91ed9cf/online.svg"
    inkscape:export-xdpi="96"
    inkscape:export-ydpi="96"
    inkscape:version="1.2.1 (9c6d41e410, 2022-07-14)"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
   <sodipodi:namedview
      id="namedview16"
      pagecolor="#ffffff"
      bordercolor="#000000"
      borderopacity="0.25"
      inkscape:showpageshadow="2"
      inkscape:pageopacity="0.0"
      inkscape:pagecheckerboard="0"
      inkscape:deskcolor="#d1d1d1"
      inkscape:document-units="mm"
      showgrid="false"
      inkscape:zoom="4.7827558"
      inkscape:cx="162.24956"
      inkscape:cy="165.69945"
      inkscape:window-width="1920"
      inkscape:window-height="1019"
      inkscape:window-x="0"
      inkscape:window-y="0"
      inkscape:window-maximized="1"
      inkscape:current-layer="svg5" />
   <defs
      id="defs2" />
   <g
      id="layer1"
      transform="translate(-46.255249,-60.905972)">
     <ellipse
        style="fill:#37a6de;fill-opacity:0.13;stroke-width:0.264583"
        id="path127"
        ry="47.022228"
        rx="49.791115"
        cy="107.9282"
        cx="96.046364" />
     <path
        style="fill:#37a6de;fill-opacity:0.22;stroke-width:0.264583"
        id="path1416"
        d="M 136.25841,107.47746 A 40.101883,38.907284 0 0 1 96.161083,146.38475 40.101883,38.907284 0 0 1 56.054643,107.48631 40.101883,38.907284 0 0 1 96.142849,68.570181 40.101883,38.907284 0 0 1 136.2584,107.45977 l -40.101875,0.0177 z" />
     <path
        style="fill:#ffffff;fill-opacity:0.9;stroke:#37a6de;stroke-width:0.185208;stroke-dasharray:none;stroke-opacity:0.5"
        id="path2924"
        d="M 121.33058,107.50474 A 25.142511,24.1679 0 0 1 96.28477,131.67246 25.142511,24.1679 0 0 1 71.046305,107.69064 25.142511,24.1679 0 0 1 95.897984,83.338446 25.142511,24.1679 0 0 1 121.32761,107.13295" />
     <path transform="scale(0.9 0.9) rotate(${ pointCog })"
          transform-origin="96 108"
        style="display:inline;fill:#065278;fill-opacity:1;stroke:none;stroke-width:0.267647;stroke-dasharray:none;stroke-opacity:0.5"
        d="M 96.095357,90.662999 83.478743,118.138 l 0.987376,1.13527 11.58929,-5.5013 11.590151,5.47189 0.91499,-1.06407 z"
        id="path10342" />
   </g>
   <g
      id="layer2"
      transform="translate(-46.255249,-60.905972)">
     <path
        style="display:none;fill:#636363;fill-opacity:1;stroke:none;stroke-width:0.185208;stroke-dasharray:none;stroke-opacity:0.5"
        id="path14963"
        d="m 118.21599,87.712273 a 7.0789323,7.0056739 0 0 1 -7.0517,7.005622 7.0789323,7.0056739 0 0 1 -7.10595,-6.951735 7.0789323,7.0056739 0 0 1 6.99705,-7.059095 7.0789323,7.0056739 0 0 1 7.15977,6.897437" />
     <path
        style="display:inline;fill:#${ color };fill-opacity:1;stroke:none;stroke-width:0.185208;stroke-dasharray:none;stroke-opacity:0.5"
        id="path18283"
        d="m 118.21599,87.712273 a 7.0789323,7.0056739 0 0 1 -7.0517,7.005622 7.0789323,7.0056739 0 0 1 -7.10595,-6.951735 7.0789323,7.0056739 0 0 1 6.99705,-7.059095 7.0789323,7.0056739 0 0 1 7.15977,6.897437" />
     <path
        style="display:none;fill:#f95738;fill-opacity:1;stroke:none;stroke-width:0.185208;stroke-dasharray:none;stroke-opacity:0.5"
        id="path18285"
        d="m 118.21599,87.712273 a 7.0789323,7.0056739 0 0 1 -7.0517,7.005622 7.0789323,7.0056739 0 0 1 -7.10595,-6.951735 7.0789323,7.0056739 0 0 1 6.99705,-7.059095 7.0789323,7.0056739 0 0 1 7.15977,6.897437" />
     <path
        style="display:none;fill:#f8c537;fill-opacity:1;stroke:none;stroke-width:0.185208;stroke-dasharray:none;stroke-opacity:0.5"
        id="path19739"
        d="m 118.21599,87.712273 a 7.0789323,7.0056739 0 0 1 -7.0517,7.005622 7.0789323,7.0056739 0 0 1 -7.10595,-6.951735 7.0789323,7.0056739 0 0 1 6.99705,-7.059095 7.0789323,7.0056739 0 0 1 7.15977,6.897437"
        inkscape:export-filename="../run/user/1000/doc/536cdf8b/flechaDetenido.svg"
        inkscape:export-xdpi="96"
        inkscape:export-ydpi="96" />
     <path
        style="display:none;fill:#a4a4a4;fill-opacity:1;stroke:none;stroke-width:0.185208;stroke-dasharray:none;stroke-opacity:0.5"
        id="path20467"
        d="m 118.21599,87.712273 a 7.0789323,7.0056739 0 0 1 -7.0517,7.005622 7.0789323,7.0056739 0 0 1 -7.10595,-6.951735 7.0789323,7.0056739 0 0 1 6.99705,-7.059095 7.0789323,7.0056739 0 0 1 7.15977,6.897437" />
     <path
        style="display:none;fill:#8ed0ea;fill-opacity:1;stroke:none;stroke-width:0.185;stroke-dasharray:none;stroke-opacity:0.5"
        id="path21195"
        d="m 118.21599,87.712273 a 7.0789323,7.0056739 0 0 1 -7.0517,7.005622 7.0789323,7.0056739 0 0 1 -7.10595,-6.951735 7.0789323,7.0056739 0 0 1 6.99705,-7.059095 7.0789323,7.0056739 0 0 1 7.15977,6.897437" />
   </g>
 </svg>
 `;
  }

  getIcon(v: Vehiculo) {
    let icon: any = null;
    let color: string = null;
    switch ( v.color ) {
      case 'verde':
        color = '8ed081';
        break;
      case 'rojo':
        color = 'f95738';
        break;
      case 'negro':
        color = '636363';
        break;
      case 'gris':
        color = 'a4a4a4';
        break;
      case 'azul':
        color = '8ed0ea';
        break;
      case 'amarillo':
        color = 'f8c537';
        break;
      default:
        color = 'a4a4a4';
        break;
    }

    if ( this.mapOptions.showCog ) {
      const svg: string = this.getSVG(color, v.point.cog);
      icon = {
        url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg), // marcador para cambiar cuando se usa cog
        scaledSize: new google.maps.Size(50, 50),
        labelOrigin: new google.maps.Point(8, 50),
        rotation: v.point.cog
      }
    } else {
      icon = {
        url: v.iconDefaultUrl, // marcador para cambiar cuando se usa cog
        scaledSize: new google.maps.Size(40, 40),
        labelOrigin: new google.maps.Point(8, 50),
      };
    }
    return icon;
  }

  createVehicleMarker(v: Vehiculo, showLabels = false) {
    const u = this.getIcon(v);
    const markerOptions = {
      position: this.googleMapLatLng(v.point.lat, v.point.lng),
      map: this.agmap,
      icon: u,
      title: v.patente,
      label: showLabels ? this.getLabelsProps(v) : ''
    };

    return new google.maps.Marker(markerOptions);
  }

  getAdjustedPositionForAlert(lat: number, lng: number): google.maps.LatLng {
    const offset = 0.00001;  // Ajusta este valor según tus necesidades.
    return new google.maps.LatLng(lat + offset, lng);
  }

  createAlertIcon(v: Vehiculo) {

    // Crear marcador de signo de interrogación parpadeante
    const questionIcon = {
      // ... Coloque aquí las propiedades del icono de signo de interrogación
      url: './alert.svg',
      scaledSize: new google.maps.Size(40, 40),
      labelOrigin: new google.maps.Point(10, 55),
    };

    const questionMarkerOptions = {
      position: this.googleMapLatLng(v.point.lat, v.point.lng),
      map: this.agmap,
      icon: questionIcon,
      title: v.patente,
      label: ""
    };
    console.log('creo el icono nuevo', questionMarkerOptions)
    return questionMarkerOptions;
  }

  getLabelsProps(v: Vehiculo) {
    return {text: v.patente, color: 'black', fontFamily: 'Helvetica', fontWeight: 'bold', fontSize: '8px'}
  }

  turnOffFitBounds() {
    if ( this.agmap ) {
      this.centerMap();
    }
    return false;
  }

  onMapReady(event: any) {
    this.agmap = event;
    this.fitBounds();
    this.agmap.addListener('rightclick', async (event) => {
      this.latPoint = event.latLng.lat();
      this.lngPoint = event.latLng.lng();
      if ( this.mapOptions.showPoints ) {
        this.createInfoWindowPoints();
      }
    });
  }

  toggleTraffic() {
    if ( this.mapOptions.showTraffic ) {
      this.trafficLayer = new google.maps.TrafficLayer();
      this.trafficLayer.setMap(this.agmap);
    } else if ( !this.mapOptions.showTraffic && this.trafficLayer != null ) {
      this.trafficLayer.setMap(null);
    }
  }

  centerMap(lat = -33.4476981, lng = -70.6405132, id: number = null) {
    if ( this.agmap ) {
      const latLng: LatLngLiteral = {lat: Number(lat), lng: Number(lng)};
      this.agmap.panTo(latLng);
      this.agmap.setZoom(id !== null ? 15 : 14);
    }
  }

  selectVehicle(imei: number) {
    if ( !isNaN(imei) ) {
      this.selectedVehicle.emit(imei);
    }
  }

  verHistorial(imei: number) {
    this.router.navigate([ '/dashboard/historialRuta' ], {
      skipLocationChange: true,
      queryParams: {imei, backCompleta: this.autoUpdate}
    });
  }

  verTemperatura(imei: number, adc: number) {
    let url = environment.endPoint + '/WaypointColdChain/GraficoFrio5.jsp';
    url = `${url}?imei=${imei}&index=${adc}&wpsid=${localStorage.getItem('wpsid')}`;

    window.open(url, '_blank');
  }

  reportClose(vehiculo: Vehiculo) {
    vehiculo.isOpenInfoWindow = false;
    this.toggleInfoWindow.emit(vehiculo);
  }

  activatePoints() {
    this.idCliente = this.loginService.idCliente;
    if ( this.mapOptions.showPoints ) {
      console.log("agregando puntos");
    } else {
      this.deletePoints();
    }
  }

  processPoints() {
    for ( const p of this.checkPoints ) {
      this.markersPoints[p.ID[0]] = this.createPointMarker(p);
    }
  }

  createPointMarker(obj: any) {
    return new google.maps.Marker({
      position: this.googleMapLatLng(-34.00004, -74.665464),
      map: this.agmap
    });
  }

  deletePoints() {
    Object.keys(this.markersPoints).forEach(mk => {
      this.markersPoints[mk].setMap(null);
      delete this.markersPoints[mk];
    });
  }

  deletePointSpecial() {
    this.markerPoint.setMap(null);
    delete this.markerPoint;
  }

  async createInfoWindowPoints() {
    this.markerPoint = this.createMarkerMap();
    this.showInfoWindowPoints();
  }

  closeInfowindowsPoint() {
    if ( this.infoWindowPoint ) {
      this.infoWindowPoint.close();
      this.infoWindowPoint = null;
      this.resetDataPoint();
    }
  }

  showInfoWindowPoints() {
    this.closeInfowindowsPoint()
    const content = this.createInfoWindowPointContent();
    this.infoWindowPoint = new google.maps.InfoWindow({
      content
    });
    this.infoWindowPoint.open(this.agmap, this.markerPoint);
    setTimeout(() => {
      this.showButtonsPoints();
    }, 500);
  }

  createInfoWindowPointContent() {
    const element = document.createElement('div');

    element.innerHTML =
      `<span class="titulo-tooltip" id="titleInfo">Opciones del Usuario</span>
      <hr>
      <div>
        <div id="wCreate">
          <div class="flex-item-1">
            <div id="wInputName">
            </div>
          </div>
          <div class="flex-item-1">
            <div id="wInputDescription">
            </div>
          </div>
          <div class="flex-item-1">
          <span class="titulo-tooltip" id="titleOptions"></span>
            <div id="wOptions1">
            </div>
            <div id="wOptions2">
            </div>
          </div>
          <div class="flex-item-2">
            <div id="wAccept">
            </div>
            <div id="wCancel">
            </div>
          </div>
        </div>
      <div class="flex-item-1">
        <div id="wPoint">
        </div>
      </div>
      <div class="flex-item-1">
        <div id="wZone">
        </div>
      </div>`;

    return element;

  }

  showButtonsPoints() {

    const point = 'btnPoint';

    const elementPoint = document.getElementById('wPoint');
    const elementZone = document.getElementById('wZone');
    const buttonPoint = `<button
                            id="btnPoint"
                            class="mat-flat-button  btn-primary crear-punto">
                              Crear Punto
                          </button>`;
    try {
      elementPoint.innerHTML = buttonPoint;

      document.getElementById(point).addEventListener('click', () => {
        elementPoint.innerHTML = '';
        elementZone.innerHTML = '';
        this.showCreatePoint();
      });

    } catch ( error ) {
      setTimeout(() => {
        this.showButtonsPoints();
      }, 500);
    }
  }

  showCreatePoint() {
    const accept = 'btnAccept';
    const cancel = 'btnCancel';
    const name = 'inName';
    const description = 'inDescription';

    const elementAccept = document.getElementById('wAccept');
    const elementCancel = document.getElementById('wCancel');
    const elementInputName = document.getElementById('wInputName');
    const elementDescription = document.getElementById('wInputDescription');
    const elementTitle = document.getElementById('titleInfo');
    const elementTitleOptions = document.getElementById('titleOptions');
    const elementOption1 = document.getElementById('wOptions1');
    const elementOption2 = document.getElementById('wOptions2');

    const buttonAccept = `<button
                            id="btnAccept"
                            class="mat-flat-button crear-punto-2">
                              Crear Punto
                          </button>`;
    const buttonCancel = `<button
                              id="btnCancel"
                              class="mat-flat-button cancelar-zona">
                                Cancelar
                            </button>`;
    const inputName = `<mat-form-field class="crear-punto-input">
                          <input matInput value="" id="inName" placeholder="Nombre de punto">
                        </mat-form-field>`;
    const inputDescription = `<mat-form-field class="crear-punto-input">
                                <input matInput value="" id="inDescription" placeholder="Descripción de punto">
                              </mat-form-field>`;

    const titleCreate = `Creacion de Nuevo Punto`;
    const titleOptionsCreate = '<p class="sub-title-punto">Seleccione un icono para el punto:</p>'
    const selectClient = '<select id="clients"></select>';
    const optionsRadio1 = ` <label class="pin-punto">
                              <span class="material-icons pin-azul">
                                location_on
                              </span>
                              <input type="radio" name="iconID" value="1"/>
                            </label>
                            <label class="pin-punto">
                              <span class="material-icons pin-azul">
                                assistant_photo
                              </span>
                              <input type="radio" name="iconID" value="2"/>
                            </label>
                            <label class="pin-punto">
                              <span class="material-icons pin-azul">
                                beenhere
                              </span>
                              <input type="radio" name="iconID" value="3"/>
                            </label>
                            <label class="pin-punto">
                              <span class="material-icons pin-azul">
                                stars
                              </span>
                              <input type="radio" name="iconID" value="4"/>
                            </label>
                            <label class="pin-punto last-pin">
                              <span class="material-icons pin-verde">
                                report_problem
                              </span>
                              <input type="radio" name="iconID" value="5"/>
                            </label>
                          `;
    const optionsRadio2 = `<label class="pin-punto">
                            <span class="material-icons pin-rojo">
                                location_on
                            </span>
                            <input type="radio" name="iconID" value="6"/>
                          </label>
                          <label class="pin-punto">
                            <span class="material-icons pin-rojo">
                              assistant_photo
                            </span>
                            <input type="radio" name="iconID" value="7"/>
                          </label>
                          <label class="pin-punto">
                            <span class="material-icons pin-rojo">
                              beenhere
                            </span>
                            <input type="radio" name="iconID" value="8"/>
                          </label>
                          <label class="pin-punto">
                            <span class="material-icons pin-rojo">
                              stars
                            </span>
                            <input type="radio" name="iconID" value="9"/>
                          </label>
                          <label class="pin-punto last-pin">
                            <span class="material-icons pin-rojo">
                              report_problem
                            </span>
                            <input type="radio" name="iconID" value="10"/>
                          </label>
                          `;
    try {
      elementAccept.innerHTML = buttonAccept;
      elementCancel.innerHTML = buttonCancel;
      elementInputName.innerHTML = inputName;
      elementDescription.innerHTML = inputDescription;
      elementTitle.innerHTML = titleCreate + '<br>' + selectClient;
      elementOption1.innerHTML = optionsRadio1;
      elementOption2.innerHTML = optionsRadio2;
      elementTitleOptions.innerHTML = titleOptionsCreate;

      const elementSelectClients = document.getElementById('clients');
      for ( const cl of this.clients ) {
        const opt = document.createElement('option');
        opt.value = cl.id;
        opt.innerHTML = cl.descripcion;
        elementSelectClients.appendChild(opt);
      }

      let client = '';
      document.getElementById(accept).addEventListener('click', () => {
        client = (document.getElementById('clients') as HTMLInputElement).value;
        this.nameInput = (document.getElementById(name) as HTMLInputElement).value;
        this.descriptionInput = (document.getElementById(description) as HTMLInputElement).value;
        this.options = document.querySelectorAll('input[name="iconID"]');
        for ( const radioOption of this.options ) {
          if ( radioOption.checked ) {
            this.option = radioOption.value;
            break;
          }
        }
        if ( this.validFields() ) {
          // llamar al servicio que crea los puntos ...
          this.pointService.createPoints(client, this.latPoint, this.lngPoint, this.nameInput, this.descriptionInput, this.option)
            .subscribe(val => {
              alert('El punto ha sido guardado correctamente');
              this.emitNewPoint.emit([]);
              setTimeout(() => {
                this.agmap.panTo({lat: this.latPoint, lng: this.lngPoint});
                this.emitNewPoint.emit(this.clients);
              }, 300);

            });
          this.closeInfowindowsPoint();
          this.deletePointSpecial();
        }
      });

      document.getElementById(cancel).addEventListener('click', () => {
        this.closeInfowindowsPoint();
      });
    } catch ( error ) {
      setTimeout(() => {
        this.showCreatePoint();
      }, 500);
    }
  }

  validFields() {
    if ( this.nameInput === '' ) {
      alert('Debe ingresar el nombre del punto.');
      return false;
    }
    if ( this.descriptionInput === '' ) {
      alert('Debe ingresar la descripcion del punto.');
      return false;
    }
    if ( this.option === '' ) {
      alert('Debe seleccionar una imagen al menos.');
      return false;
    }
    return true;
  }

  makePoints(client: string) {

  }

  createMarkerMap() {
    return new google.maps.Marker({
      position: this.googleMapLatLng(this.latPoint, this.lngPoint),
      map: this.agmap,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 0
      }
    });
  }

  createAngularMarker() {

  }

  resetDataPoint() {
    this.nameInput = '';
    this.descriptionInput = '';
    this.option = '';
  }

  createInfoWindowContentUnifi(v: Vehiculo) {
    const element = document.createElement('div');
    console.log("createInfoWindowContentUnifi")
    console.log(v)
    if ( this.isClientConfigurate() ) {
      element.innerHTML =
        `<p>${v}</p>
        <span class="titulo-tooltip">${ v.patente } ${ v.descripcion !== '' ? '-' + v.descripcion : '' }</span>
      <hr>
      <div class="flex-item-2">
        <div id="wDetalle">
        <button
          id="btnDetalle"
          class="mat-button btn-primary">
            Detalle
        </button>
        </div>
        <div id="wAlerta">
        <button
          id="btnAlerta"
          class="mat-button btn-primary">
            Alerta
        </button>
        </div>
        <div id="wHistorial">
          <button
            id="btnHistorial"
              class="mat-button">
              Historial
          </button>
        </div>
      </div>
      <div id="wTemp">
      </div>`;

    } else {
      element.innerHTML =
        `<span class="titulo-tooltip">${ v.patente } ${ v.descripcion !== '' ? '-' + v.descripcion : '' }</span>
      <hr>
      <div class="flex-item-2">
        <div id="wDetalle">
        <button
          id="btnDetalle"
          class="mat-button btn-primary">
            Detalle
        </button>
        </div>
        <div id="wHistorial">
          <button
            id="btnHistorial"
              class="mat-button">
              Historial
          </button>
        </div>
      </div>
      <div id="wTemp">
      </div>`;
    }
    console.log(element.innerHTML)
    return element;
  }

  isClientConfigurate(): boolean {
    return this.clients.some(obj => this.idClientConfigurate.includes(obj.id));
  }

  async getClientConfigurateWithTxt() {
    try {
      const data = await this.http.get('./configFlotaCompleta.txt', {responseType: 'text'}).toPromise() ;

      const ids = data.split(',').map(id => Number(id.trim())); // asume que los IDs están separados por comas
      this.idClientConfigurate = ids;
    } catch ( error ) {
      console.log(error);
    }
  }

  addListenerButtons(v: Vehiculo) {
    const detalle = 'btnDetalle';
    const historial = 'btnHistorial';
    const alerta = 'btnAlerta';
    console.log('isClient', this.isClientConfigurate());
    try {
      document.getElementById(detalle).addEventListener('click', () => {
        this.openPopUp(v.imei);
      });
      if ( this.isClientConfigurate() ) {
        document.getElementById(alerta).addEventListener('click', () => {
          this.selectVehicle.bind(this);
          console.log('todo el arreglo', v);
          this.openPopUpAlerta(v);
        });
      }
      document.getElementById(historial).addEventListener('click', () => {
        this.verHistorial.bind(this);
        this.verHistorial(v.imei);
      });
    } catch ( error ) {
      console.log(error);
      setTimeout(() => {
        this.addListenerButtons(v);
      }, 500);
    }
  }

}
