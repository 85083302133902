<app-detalle-historial-ruta 
    class="detail"
    [setData]="this.historicData"
    >
</app-detalle-historial-ruta>

<app-opciones-historial-ruta class="options" (selectedOptions)="makeHistoricRoute($event)">
</app-opciones-historial-ruta>


<app-mapa-historico 
    [setPolyline]="this.historicData ? this.historicData.routePoints : []" 
    [inicio]="this.historicData ? this.historicData.startMarker : null"
    [setPuntosRuta]="this.historicData ? this.historicData.routePoints : []" 
    [setPuntoOrigen]="this.historicData ? this.historicData.startMarker : null"
    [setPuntoDestino]="this.historicData ? this.historicData.endMarker : null">
</app-mapa-historico>