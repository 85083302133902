import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NotificationService } from "../../_services/notification.service";
import { Alert } from "../../_interfaces/alerts";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: [ './notification.component.css' ]
})
export class NotificationComponent implements OnInit, OnDestroy {
  alertsVehicles: Alert[][] = [];
  private subscription: Subscription;
  @Output() emitNotification = new EventEmitter<any>();

  constructor(private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.subscription = this.notificationService.notificationEvent.subscribe((alerts: Alert[]) => {
      this.tiposAlerta(alerts);
      this.alertsVehicles.push(alerts);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close(alertsToRemove: Alert[]) {
    const index = this.alertsVehicles.indexOf(alertsToRemove);
    if ( index > -1 ) {
      this.alertsVehicles.splice(index, 1);
    }
    this.emitNotification.emit(alertsToRemove[0]);
  }

  tiposAlerta(alerts: Alert[]): void {
      
    const eventTypeNames = {
      1: 'Apertura de Puerta',
      2: 'Detención Excesiva',
      3: 'Exceso de Velocidad',
      4: 'Exceso de Velocidad por flota',
      5: 'Desconexión Eléctrica',
      6: 'Pérdida de Ubicación GPS',
      101: 'Apertura de Puerta',
      102: 'Detención Excesiva',
      103: 'Exceso de Velocidad',
      104: 'Exceso de Velocidad por flota',
      105: 'Desconexión Eléctrica',
      106: 'Pérdida de Ubicación GPS',
    };
  
    const alertTypeNames = {
      2: 'Pánico',
      3: 'Jammer',
      5: 'Somnolencia',
      102: 'Pánico',
      103: 'Jammer',
    };
    alerts.forEach((alert) => {
      if (alert.alertType === 1) {
        if (alert.eventType === 1 && alert.idOpenDoor.length > 0) {
          alert.nameEvent = alert.idOpenDoor;
        } else {
          alert.nameEvent = eventTypeNames[alert.eventType];
        }
      } else if (alertTypeNames[alert.alertType]) {
        alert.nameEvent = alertTypeNames[alert.alertType];
      } else if (alert.eventType === 101 && alert.idOpenDoor.length > 0) {
        alert.nameEvent = alert.idOpenDoor;
      } else if (eventTypeNames[alert.eventType]) {
        alert.nameEvent = eventTypeNames[alert.eventType];
      } 
    });
  }
}
