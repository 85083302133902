
<div class="sercher">
  <div>
    <input
      id="location"
      class="sercherText"
      (keydown.enter)="$event.preventDefault()"
      placeholder="Busque la dirección aquí"
      autocorrect="on"
      autocapitalize="off"
      spellcheck="off"
      type="text"
      #search
      [(ngModel)]="searchAddresText"
      (keydown)="onKeydown($event)"
      >
  </div>
  <div >
    <button class="serchButton" (click)="searchByCoords()" #serchbutton>Buscar</button>
  </div>
</div>
<div id="map" *ngIf="!this.flotaCompleta">

  <agm-map
  [mapTypeControlOptions]="mapTypeControlOptions"
  [mapTypeControl]=true
  [rotateControl]=true
  [fullscreenControl]=false
  [usePanning]="false"
  [zoom]="actualZoom"
  (mapReady)="onMapReady($event)"
  class="block-map"
  [latitude]="lat"
  [longitude]="lng"
  >

  <div *ngIf="this.mapOptions.showZones" >
    <agm-polygon
      *ngFor="let z of zonesList"
      [fillColor]="z.color"
      (polyMouseOut)="hideZoneName(z)"
      (polyMouseOver)="showZoneName(z)"
      [paths]="z.path">
    </agm-polygon>
  </div>

  <div *ngIf="this.routePoints" >
    <agm-polyline [strokeColor]="'#2196f3'" [strokeOpacity]="0.5">
      <agm-polyline-point
        *ngFor="let point of routePoints"
        [latitude]="point.lat"
        [longitude]="point.lng"
      >
      </agm-polyline-point>
    </agm-polyline>
  </div>

  <div *ngIf="this.mapOptions.showPoints" >
    <agm-marker
      *ngFor="let p of this.pointsList"
      [latitude]="p.lat"
      [longitude]="p.lng"
      [iconUrl]="p.icon"
      [title]="p.name"
      [markerDraggable]="true"
      (dragEnd)="markerDragEnd($event)">
    </agm-marker>
  </div>

    <agm-marker
      *ngIf="this.serchedMarker"
      [latitude]="serchedMarker.lat"
      [longitude]="serchedMarker.lng"
      [iconUrl]="serchedMarker.icon"
      [title]="serchedMarker.name"
      [markerDraggable]="false">
    </agm-marker>
</agm-map>
</div>
<div id="map" *ngIf="this.flotaCompleta">
  <agm-map
    [mapTypeControlOptions]="mapTypeControlOptions"
    [mapTypeControl]=true
    [rotateControl]=true
    [fullscreenControl]=false
    [usePanning]="false"
    [zoom]="actualZoom"
    (mapReady)="onMapReady($event)"
    class="block-map-flota"
    [latitude]="lat"
    [longitude]="lng"
    >

    <div *ngIf="this.mapOptions.showZones" >
      <agm-polygon
        *ngFor="let z of zonesList"
        [fillColor]="z.color"
        (polyMouseOut)="hideZoneName(z)"
        (polyMouseOver)="showZoneName(z)"
        [paths]="z.path">
      </agm-polygon>
    </div>

    <div *ngIf="this.routePoints" >
      <agm-polyline [strokeColor]="'#2196f3'" [strokeOpacity]="0.5">
        <agm-polyline-point
          *ngFor="let point of routePoints"
          [latitude]="point.lat"
          [longitude]="point.lng"
        >
        </agm-polyline-point>
      </agm-polyline>
    </div>

    <div *ngIf="this.mapOptions.showPoints" >
      <agm-marker
        *ngFor="let p of this.pointsList"
        [latitude]="p.lat"
        [longitude]="p.lng"
        [iconUrl]="p.icon"
        [title]="p.name"
        [markerDraggable]="true"
        (dragEnd)="markerDragEnd($event)">
      </agm-marker>
    </div>

      <agm-marker
        *ngIf="this.serchedMarker"
        [latitude]="serchedMarker.lat"
        [longitude]="serchedMarker.lng"
        [iconUrl]="serchedMarker.icon"
        [title]="serchedMarker.name"
        [markerDraggable]="false">
      </agm-marker>
  </agm-map>
  </div>

<div class="zoneName" *ngIf="nameZona !== '' && this.mapOptions.showZones" >
  <b>Zona:</b> {{nameZona}}
</div>
