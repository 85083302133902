<div id="modal-content-wrapper">
    <form [formGroup]="informeForm">
        <h4 id="titulo">Generar informe horómetros consolidados</h4>
        <div mat-dialog-content>
            <p>Selecciona la fecha y hora del reporte</p>
            <mat-radio-group class="group-wrapper" name="agrupar-por" aria-label="Agrupar por" formControlName="agruparPor" required>
                <mat-label>Agrupar por: </mat-label>
                <mat-radio-button value="dia">Día</mat-radio-button>
                <mat-radio-button value="semana">Semana</mat-radio-button>
                <mat-radio-button value="mes">Mes</mat-radio-button>
            </mat-radio-group>
            <div id="desde-wrapper" class="group-wrapper">
                <mat-form-field appearance="outline" class="datepicker">
                    <mat-label>Desde</mat-label>
                    <input matInput [matDatepicker]="desde" name="fechaDesde" formControlName="fechaDesde" required>
                    <mat-hint>DD/MM/AAAAA</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="desde"></mat-datepicker-toggle>
                    <mat-datepicker #desde></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline" class="time-select">
                    <mat-select formControlName="horaDesde">
                        <mat-option *ngFor="let hour of hours" [value]="hour.value">
                            {{ hour.viewValue }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> : 
                <mat-form-field appearance="outline" class="time-select">
                    <mat-select formControlName="minutoDesde">
                        <mat-option *ngFor="let minute of minutes" [value]="minute.value">
                            {{ minute.viewValue }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div id="hasta-wrapper" class="group-wrapper">
                <mat-form-field appearance="outline" class="datepicker">
                    <mat-label>Hasta</mat-label>
                    <input matInput [matDatepicker]="hasta" name="fechaHasta" formControlName="fechaHasta" required>
                    <mat-hint>DD/MM/AAAA</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="hasta"></mat-datepicker-toggle>
                    <mat-datepicker #hasta></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline" class="time-select">
                    <mat-select formControlName="horaHasta">
                        <mat-option *ngFor="let hour of hours" [value]="hour.value">
                            {{ hour.viewValue }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> : 
                <mat-form-field appearance="outline" class="time-select">
                    <mat-select formControlName="minutoHasta">
                        <mat-option *ngFor="let minute of minutes" [value]="minute.value">
                            {{ minute.viewValue }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div id="buttons" class="group-wrapper">
                <button id="enviar" type="submit" mat-raised-button (click)="enviarDatos()">Obtener informe</button>
                <button id="cerrar" mat-raised-button (click)="closeModal()">Cerrar</button>
            </div>
        </div>
    </form>
</div>