import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Alert } from '../../_interfaces/alerts';

@Component({
  selector: 'app-detalle-alerta',
  templateUrl: './detalle-alerta.component.html',
  styleUrls: [ './detalle-alerta.component.css' ]
})
export class DetalleAlertaComponent implements OnInit {
  alertVehicle: Alert[];
  isLoading = true;
  readonly noDisponible = 'N/D';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<DetalleAlertaComponent>) {
    console.log('desde el modal', data);
    if ( data && data !== undefined && data.alert.length > 0 ) {
      this.alertVehicle = data.alert;
      this.isLoading = false;
    }
  }


  ngOnInit() {
    this.tiposAlerta();
  }

  tiposAlerta(): void {
    if (!this.alertVehicle) {
      return;
    }
  
    const eventTypeNames = {
      1: 'Apertura de Puerta',
      2: 'Detención Excesiva',
      3: 'Exceso de Velocidad',
      4: 'Exceso de Velocidad por flota',
      5: 'Desconexión Eléctrica',
      6: 'Pérdida de Ubicación GPS',
      101: 'Apertura de Puerta',
      102: 'Detención Excesiva',
      103: 'Exceso de Velocidad',
      104: 'Exceso de Velocidad por flota',
      105: 'Desconexión Eléctrica',
      106: 'Pérdida de Ubicación GPS',
    };
  
    const alertTypeNames = {
      2: 'Pánico',
      3: 'Jammer',
      5: 'Somnolencia',
      102: 'Pánico',
      103: 'Jammer',
    };
  
    this.alertVehicle.forEach((alert) => {
      if (alert.alertType === 1) {
        if (alert.eventType === 1 && alert.idOpenDoor.length > 0) {
          alert.nameEvent = alert.idOpenDoor;
        } else {
          alert.nameEvent = eventTypeNames[alert.eventType];
        }
      } else if (alertTypeNames[alert.alertType]) {
        alert.nameEvent = alertTypeNames[alert.alertType];
      } else if (alert.eventType === 101 && alert.idOpenDoor.length > 0) {
        alert.nameEvent = alert.idOpenDoor;
      } else if (eventTypeNames[alert.eventType]) {
        alert.nameEvent = eventTypeNames[alert.eventType];
      } 
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
