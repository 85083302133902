
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { environment } from './../../../environments/environment';
import { catchError,retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class PointsService {

    constructor(private http: HttpClient) { }

    getPoints(cl: any) {
        let idCliente = cl;
        if (isNaN(idCliente)) {
            idCliente = idCliente.id;
        }
        const url = `${environment.endPoint}/clientes/xml/getCheckpoints.php?func=listarpuntos&rut=${idCliente}`;
        return this.http.get(url, { responseType: 'text' }).pipe(
            retry(3),
            catchError(err => this.errorHandler(err))
        );
    }

    async getPointsList(idCliente: number) {
        const url = `${environment.endPoint}/clientes/xml/getCheckpoints.php?func=listarpuntos&rut=${idCliente}`;
        let result: any;
        await this.http.get(url, { responseType: 'text' }).toPromise()
        .then(res => {
            result = res;
        });
        return result;
    }

    createPoints(idCliente: any, lat: any, lon: any, name: string, description: string, option: string ) {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        const body = {
            idCliente,
            lat,
            lon,
            nombre: name,
            descripcion: description,
            radio_icono: option
        };

        const url = `${environment.endPoint}/clientes/guardarCheckPointAngular.php`
        return this.http.post(url, body , { headers, responseType: 'text'});
    }

    errorHandler = (error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
        } else {
            errorMsg = `Código de error: ${error.status}, Mensaje: ${error.message}`
        }
        console.error(errorMsg);
        return throwError(errorMsg)
    }
}
