<ng-container *ngIf="alertsVehicles.length > 0">
  <div class="notification-container">
    <div class="notification-content">
      <div class="alert-scroll-container">
        <ng-container *ngFor="let alerts of alertsVehicles">
          <!-- Sección para cada vehículo -->
          <div class="vehicle-section">
            <ng-container *ngFor="let alert of alerts">
              <div class="alert-card"
                   [ngClass]="{
                       'panico-color': alert.nameEvent === 'Pánico',
                       'puerta-color': alert.nameEvent === 'Apertura de Puerta Trasera'
                    }">
                <div class="alert-field">
                  <b>Patente: </b>
                  <span>{{ alert.plate }}</span>
                </div>
                <div class="alert-field">
                  <b>Fecha: </b>
                  <span>{{ alert.initEvent | date:'dd-MM-yyyy HH:mm:ss' }}</span>
                </div>
                <div class="alert-field">
                  <b>Tipo: </b>
                  <span>{{ alert.nameEvent }}</span>
                </div>
              </div>
            </ng-container>
            <!-- Botón para cerrar las alertas del vehículo actual -->
            <button (click)="close(alerts)">VER</button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
