<!--INICIO Seleccion de cliente-->
<mat-slide-toggle color="primary" class="toggle-filtros" [(ngModel)]="this.filtros">Desplegar Filtros:</mat-slide-toggle>

<div *ngIf="filtros" >
<div class="filtros-div">
    <app-select-con-filtro
        [setOpciones]="clients"
        [deshabilitado]="false"
        [label]="'Clientes'"
        [setOpcion]="this.controls.clients"
        [guardarSeleccion]="true"
        (opcionSeleccionada)="selectChange($event, 'clients', true)"
    >
    </app-select-con-filtro>
</div>
<div class="filtros-div">
    <mat-form-field class="filtros-input">
        <mat-label>Grupos</mat-label>
        <mat-select
            [multiple]="true"
            (selectionChange)="selectChange($event, 'groups', true)"
            [(ngModel)]="this.controls.groups"
            >
            <div class="filter-option item-end text-center">
                <button
                    mat-stroked-button color="primary"
                    class="fill text-sm btn"
                    (click)="selectAll('groups')">
                        Seleccionar todo
                </button>
                <button
                    mat-stroked-button
                    class="fill text-sm float-right"
                    (click)="deselectAll('groups')">
                    Borrar selección
                </button>
            </div>

            <mat-optgroup *ngFor="let client of this.selectOptions.groups" [label]="getClientName(client.id)"
                [disabled]="false">
                <mat-option *ngFor="let group of client.items" [value]="group.id">
                    {{group.nombre}}
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
</div>
<div class="filtros-div">
    <mat-form-field class="filtros-input">
        <mat-label>Flotas</mat-label>
        <mat-select [multiple]="true" (selectionChange)="selectChange($event, 'fleets', true)" [(ngModel)]="this.controls.fleets">
            <div class="filter-option">
                <button mat-stroked-button color="primary" class="fill text-sm" (click)="selectAll('fleets')">
                    Seleccionar todo
                </button>
                <button mat-stroked-button class="fill text-sm float-right" (click)="deselectAll('fleets')">
                    Borrar selección
                </button>
            </div>
            <mat-optgroup *ngFor="let client of this.selectOptions.fleets" [label]="getClientName(client.id)"                        [disabled]="false">
                    <mat-option *ngFor="let fleet of client.items" [value]="fleet.id">
                        {{fleet.name}}
                    </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
</div>

<div class="filtros-div">
    <mat-form-field class="filtros-input">
        <mat-label>Vehiculos</mat-label>
        <mat-select [multiple]="true" (selectionChange)="selectChange($event, 'vehicles')" [(ngModel)]="this.controls.vehicles">
            <div class="filter-option">
                <button mat-stroked-button color="primary" class="fill text-sm" (click)="selectAll('vehicles')">
                    Seleccionar todo
                </button>
                <button mat-stroked-button class="fill text-sm float-right" (click)="deselectAll('vehicles')">
                    Borrar selección
                </button>
            </div>
            <div class="filter-option">
                <input
                    class="input-buscar"
                    matInput
                    placeholder="Ingrese texto para filtrar vehículos"
                    maxlength="50"
                    [(ngModel)]="filtroOpcionesVehiculos"
                    (keydown)="$event.stopPropagation()"
                />
            </div>
            <mat-optgroup
                *ngFor="let client of this.selectOptions.vehicles"
                [label]="client.name"
                [disabled]="false"
            >
              <ng-container *ngFor="let vehicle of client.items">
                <mat-option
                  *ngIf="selectedVehicles.includes(vehicle.id) || vehicle.patente.toLowerCase().indexOf(filtroOpcionesVehiculos.toLowerCase()) > -1"
                  [value]="vehicle.id">
                  {{vehicle.patente}}
                </mat-option>
              </ng-container>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
</div>
</div>

<h4 class="text-filter-2">Resultados  de filtros:</h4>
<div [ngClass]="this.filtros ? 'div-resultados' : 'div-resultados-grande'">
    <div id="clientes">
    </div>
</div>

<!-- INICIO PREFERENCIAS DE MAPA-->
<app-preferencias-mapa (toggle)="toggleMapOptions($event)" [isClientConfigurate]="this.clientConfigurate" [isClientConfigurateOnlyOnRoute]="this.clientConfigurateOnRoute">
</app-preferencias-mapa>
<!-- INICIO PREFERENCIAS DE MAPA-->
