import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { WPSID } from 'app/_class/wpsid';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class FleetsService {

  wpsid: WPSID = new WPSID('');

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  async getFleets(idCliente: number, grupo = null){

    // Token es la cookie at (access token) de cognito. Necesario si se quiere consultar
    // los grupos de un cliente filtrados por los que tiene disponible el usuario.
    // El filtro solo aplica a usuarios supervisores y normales, mas no a administradores.
    let token = '';
    if ( this.cookieService.check('at') ) {
      token = '&user_token=' + this.cookieService.get('at');
    }
    let url = `${environment.endPoint}/Req/GetClase?${this.wpsid.getUrlEncode()}${token}`;

    if (idCliente > 0) {
      url += `&cliente=${idCliente}`;
    }

    if (grupo !== null) {
      url += `&grupo=${grupo}`;
    }
    url += token;

    let fleets: any[] = [];

    await this.http.get<any[]>(url)
    .toPromise()
    .then(data => {
      fleets = data;
    });

    return fleets;
  }
}
