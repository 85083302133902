import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-modulo-bi',
  templateUrl: './modulo-bi.component.html',
  styleUrls: ['./modulo-bi.component.css']
})
export class ModuloBiComponent implements OnInit {
  token: string;
  cargado = false;
  url: any;
  constructor(private readonly http: HttpClient, private readonly san: DomSanitizer) {   
  }

  ngOnInit() {
    this.cargado = false;
    this.http.get('https://api-dev.waypoint.cl/bi/v1/token', {responseType: 'text'}).toPromise().then(t => {
      this.token = t;
      this.cargado = true;
      console.log(this.token);
      this.url = this.san.bypassSecurityTrustResourceUrl(`https://api-dev.waypoint.cl/bi.emb/v1/embebido/${this.token}`);
    }).catch(err => {
      console.log(err);
    });
  }

}
