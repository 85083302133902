import { Injectable } from '@angular/core';
import { JsonHc } from 'app/_interfaces/json-hc';
import { Grupo } from 'app/_class/grupo';
import { ClaseVehiculo } from 'app/_class/clase-vehiculo';
import { Subject, Observable } from 'rxjs';
import { LoginService } from './login.service';
import { Cliente } from 'app/_class/cliente';

import { Point } from 'app/_class/point';
import { HTTPService } from './http.service';
import { RegistroHC } from 'app/_class/registro-hc';
import { StorageService } from './storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  // segundos entre cada actualizacion de vehiculos
  intervalUpdate = 15000;
  // Para ejecutar cada intervalUpdate la data de la flota
  modoFlota = false;
  idsFlotaSelected = new Set<number>();
  flotaSelected: RegistroHC[];
  $flotaUpdated = new Subject<boolean>();

  /** array que  de clientes asosciados a un usuario con sus vehiculos respectivos */
  flotaByClient: Cliente[] = [];
  /** emite true cuando se han cargado los la flota ( Vehiculos  asociados al usuario) */
  $flotaCargada = new Subject<boolean>();

  $idCliente = new Subject<number>();
  idCliente: number;
  jsonHc: JsonHc;
  grupos: Grupo[] = [];
  $grupos = new Subject<Grupo>();
  clases: ClaseVehiculo[] = [];
  $clases = new Subject<ClaseVehiculo>();
  clientes: Cliente[] = [];
  /**  Envia una evento con los ids de los vehiculos [] que cambiaron de posicion */
  $updatePosicionVeh = new Subject<number[]>();
  // notifica  cuando se ha actualizado  el JsonHc
  $hasUpdateJsonHc = new Subject<boolean>();
  $dataLoad = new Subject<boolean>();

  constructor(private loginService: LoginService, private httpService: HTTPService, private storage: StorageService) {
  }

  // Actualiza el jsonHc para luego notificar los cambios de posiciones mediante $updatePosicionVeh
  // verifica si hubo cambio de posicion
  updateJsonHc(data: JsonHc) {

    const idsUpdated: number[] = [];

    if (this.jsonHc === undefined || this.jsonHc.contenido.registro.length === 0) {
      this.jsonHc = data;
    } else if (data.estado !== undefined) {

      const metaIdVeh = this.jsonHc.meta.id;
      const metaImei = this.jsonHc.meta.imei;
      const metaLat = this.jsonHc.meta.lat;
      const metaLng = this.jsonHc.meta.lon;

      this.jsonHc.contenido.registro.forEach((veh, index, arr) => {

        const newVeh = data.contenido.registro.find(v => v[metaImei] === veh[metaImei]);

        if (newVeh !== undefined || this.jsonHc.contenido.registro.length === 0) {

          const newPoint = new Point(newVeh[metaLat], newVeh[metaLng]);
          const oldPoint = new Point(veh[metaLat], veh[metaLng]);

          let direccion = this.jsonHc.contenido.registro[index].direccion;
          // si  cambió de posicion
          if (!newPoint.equals(oldPoint)) {
            idsUpdated.push(this.jsonHc.contenido.registro[index][metaIdVeh]);
            direccion = ' - ';
          }
          newVeh.direccion = direccion;
          this.jsonHc.contenido.registro[index] = newVeh;

        } else {
          console.warn('se intentó actualizar un vehiculo que no se encuentra cargado');
        }
      });
    } else {
      console.log('*** Error  se intentó actualizar la data de vehiculos con  ' + JSON.stringify(data));
    }

    this.$hasUpdateJsonHc.next(true);

    if (idsUpdated.length > 0) {
      this.$updatePosicionVeh.next(idsUpdated);
    }
  }

  /* recupera el ultimo id de cliente seleccionado
  ó el primer id de cliente disponible para el usuario
  e invoca la carga de datos para ese cliente*/
  recoveryIdClient(): boolean {
    let id = -1;
    // TODO logica para recuperar el ultimo id de cliente usado

    if (this.clientes.length > 0) {
      id = this.clientes[0].id;
      this.$idCliente.next(id);
    }

    return id > 0;
  }
  /**  Retorna la lista de "clases"  que son usadas por la flota */
  getClasesFiltered(): ClaseVehiculo[] {
    const metaClase = this.jsonHc.meta.clase;
    const listIdClases = this.jsonHc.contenido.registro.map(reg => reg[metaClase]);
    return this.clases.filter(c => listIdClases.includes(c.idFlota));
  }

  getMeta() {
    return this.jsonHc.meta;
  }

  getRegistroHcByImei(imei: number): Observable<JsonHc> {
    return this.httpService.getRegistroHcByImei(imei, this.loginService.wpsid.getUrlEncode());
  }

  /**
   * Get item RegistroHc by imei or null  if the imei not exist in {this.flotaSelected} or in {this.jsonHc}
   *
   * @param number imei
   * @returns RegistroHC
   */
  getRegistroHc(imei: number): RegistroHC {
    let veh: RegistroHC = null;
    if (this.modoFlota && this.flotaSelected.some(f => f.W2 === imei)) {
      veh = this.flotaSelected.find(f => f.W2 === imei);
    } else if (this.jsonHc.contenido.registro.some(v => v[this.jsonHc.meta.imei] === imei)) {
      veh = this.jsonHc.contenido.registro.find(v => v[this.jsonHc.meta.imei] === imei);
    }

    return veh;
  }
}
