import { MapOptions } from './../../_class/map-options';
import { Component, EventEmitter, Output, Input } from '@angular/core';


@Component({
  selector: 'app-preferencias-mapa',
  templateUrl: './preferencias-mapa.component.html',
  styleUrls: ['./preferencias-mapa.component.css']
})
export class PreferenciasMapaComponent {
  @Input()
  isClientConfigurate: boolean;
  @Input()
  isClientConfigurateOnlyOnRoute: boolean;
  mapOptions = new MapOptions();
  @Output() toggle = new EventEmitter<MapOptions>();


  doChange(value: boolean, type: string) {
    console.log(value, type);
    this.mapOptions.toggle(value, type);
    this.toggle.emit(this.mapOptions);
  }
}
