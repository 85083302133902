<mat-form-field fxFlex="98">
  <mat-label>{{ label }}</mat-label>
  <mat-select [(ngModel)]="opcion" [disabled]="deshabilitado" multiple={{multiple}} (selectionChange)="opcionCambiada()">
    <input
      class="input-buscar"
      matInput
      placeholder="Ingrese texto para filtrar busqueda"
      maxlength="50"
      [(ngModel)]="filtroOpcion"
      (keyup)="filtrarOpciones()"
      (keydown)="$event.stopPropagation()"
    />

    <mat-option class="selector-buscar"
                [value]="0"
                *ngIf="defecto"
                (click)="opcionCambiada()">
      Sin seleccion
    </mat-option>

    <mat-button-toggle class="ocultar-boton"
                       [value]="all"
                       *ngIf="filtrarOpciones().length > 0 && todos" (click)="selectAll()">
      Seleccionar todo
    </mat-button-toggle>

    <mat-option class="selector-buscar"
                [value]="opcion.id"
                (click)="opcionCambiada()"
                *ngFor="let opcion of filtrarOpciones()"
                [matTooltip]="opcion.descripcion.length < 36 ? null :  opcion.descripcion"
                matTooltipClass="buscadorDashboardtooltip"
                class="buscadorDashboard"
    >{{opcion.descripcion}}</mat-option>

    <mat-option [value]="0"
                (click)="opcionCambiada()"
                *ngIf="filtrarOpciones().length === 0 && !defecto">
      Sin resultados
    </mat-option>
  </mat-select>
</mat-form-field>
