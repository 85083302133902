import { FechaUtilService } from 'app/_utils/fecha-util/fecha-util.service';
import { Options } from './../../_class/Options/options';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetHistorialService } from 'app/_services/historial/get-historial.service';
import { MapsUtilService } from 'app/_services/maps-util/maps-util.service';
import { HistoricData } from 'app/_class/historicData/historic-data';

@Component({
  selector: 'app-historic-data',
  templateUrl: './historic-data.component.html',
  styleUrls: ['./historic-data.component.css']
})
export class HistoricDataComponent implements OnInit {

  historicData: HistoricData;

  constructor(
    private readonly historicService: GetHistorialService,
    public router: Router,
    private readonly fus: FechaUtilService,
    private readonly mus: MapsUtilService) { }

  ngOnInit() {
  }

  makeHistoricRoute(options: Options) {
    const fechaInit = this.formatDate(options.startDateTime);
    const fechaEnd =  this.formatDate(options.endDateTime);
    const timeStart = this.formatTime(options.hourStart, options.minuteStart);
    const timeEnd = this.formatTime(options.hourEnd, options.minuteEnd);
    const fechaInicioFormat =  `${fechaInit} ${timeStart}`;
    const fechaFinFormat = `${fechaEnd} ${timeEnd}`;
    const stopInSeconds = options.stopTimeSelected  * 60;
    this.historicService.get(
      options.vehicleSelected,
      options.ignition,
      stopInSeconds,
      options.timezone,
      options.precision,
      fechaInicioFormat,
      fechaFinFormat).then(res => {
        if (res === null ) { 
          return; 
        }

        this.historicData = new HistoricData(res, this.fus, this.mus);
      });
  }

  formatDate(date) {
    var d = new Date(date),
    month = `${(d.getMonth() + 1)}`,
    day = `${d.getDate()}`,
    year = d.getFullYear();
    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }
    return [year, month, day].join('-');
  }

  formatTime(hour, minutes){
    const seconds = '00';
    hour  = hour <= 9 ? String("0" + hour): hour;
    minutes  = minutes <= 9 ? String("0" + minutes): minutes;
    return [hour, minutes, seconds].join(':');  
  }

}
