import { Indicador } from './indicador';

export class FlotaActiva  extends Indicador  {
  activos = 0;
  inactivos = 0;
  total = 0;

  constructor(data?: any | FlotaActiva) {
    super();
    if (data !== undefined) {
      Object.assign(this, data);
    }
  }
}
